import AverageSalePrice from './Metrics/AverageSalePrice'
import DaysToReachXStage from './Metrics/DaysToReachXStage'
import DealsToReachXStage from './Metrics/DealsToReachXStage'
import NewPipelineByRep from './Metrics/NewPipelineByRep'
import OpenPipelineByRep from './Metrics/OpenPipelineByRep'
import OpenPipelineByStage from './Metrics/OpenPipelineByStage'
import OpportunitiesCreatedByChannel from './Metrics/OpportunitiesCreatedByChannel'
import OpportunityWinRate from './Metrics/OpportunityWinRate'
import TotalClosedWon from './Metrics/TotalClosedWon'

import { BsCalendar3, BsFillPeopleFill } from 'react-icons/bs'
import { FaDollarSign } from 'react-icons/fa'
import { IoMdTrendingUp } from 'react-icons/io'
import { MdCreate, MdStackedBarChart } from 'react-icons/md'
import { RiSlowDownFill } from 'react-icons/ri'
import { Field, MetricMetadata } from './Types'

const CREATED_DATE: Field = {
  name: 'CreatedDate',
  label: 'Created Date',
  type: 'date',
}
const CLOSE_DATE: Field = {
  name: 'CloseDate',
  label: 'Close Date',
  type: 'date',
}
const OWNER_ID: Field = { name: 'OwnerId', label: 'Owner', type: 'string' }

const availableMetrics: Record<string, MetricMetadata> = {
  'deals-to-reach-x-stage': {
    name: 'Deals to Reach X Stage',
    component: DealsToReachXStage,
    usesBucketToggle: true,
    icon: <MdStackedBarChart color='#CC4E00' size={22} />,
    backgroundColor: '#FFDFB5',
    description: 'Number of deals that have progressed to a certain stage',
    usesStages: true,
    usesAmountField: false,
    cohortField: CREATED_DATE,
  },
  'days-to-reach-x-stage': {
    name: 'Days to Reach X Stage',
    component: DaysToReachXStage,
    usesBucketToggle: true,
    icon: <BsCalendar3 color='#5753C6' size={23} />,
    backgroundColor: '#E6E7FF',
    description:
      'Average number of days it took for Opportunities to reach a specific stage',
    usesStages: true,
    usesAmountField: false,
    cohortField: CREATED_DATE,
  },
  'opportunity-win-rate': {
    name: 'Opportunity Win Rate',
    component: OpportunityWinRate,
    usesBucketToggle: true,
    icon: <IoMdTrendingUp color='#0D74CE' size={22} />,
    backgroundColor: '#D5EFFF',
    description: 'Percentage of deals that are won for a cohort',
    usesStages: false,
    usesAmountField: false,
    cohortField: CREATED_DATE,
  },
  'average-sale-price': {
    name: 'Average Sale Price',
    component: AverageSalePrice,
    usesBucketToggle: true,
    icon: <FaDollarSign color='#208368' size={20} />,
    backgroundColor: '#D6F1E3',
    description: 'Average price at which deals are won',
    usesStages: false,
    usesAmountField: true,
    cohortField: CLOSE_DATE,
  },
  'average-sales-cycle': {
    name: 'Average Sales Cycle',
    component: null,
    usesBucketToggle: true,
    icon: <RiSlowDownFill color='#5753C6' size={23} />,
    backgroundColor: '#E6E7FF',
    description: 'Average duration from opportunity start to close',
    usesStages: false,
    usesAmountField: false,
  },
  'opportunities-created-by-channel': {
    name: 'Opportunities Created By Channel',
    component: OpportunitiesCreatedByChannel,
    usesBucketToggle: true,
    segmentation: {
      field: 'LeadSource',
      configurable: true,
      toggleable: false,
    },
    icon: <MdCreate color='#65636D' size={21} />,
    backgroundColor: '#EAE7EC',
    description: 'Number of created deals by source',
    usesStages: false,
    usesAmountField: false,
    cohortField: CREATED_DATE,
  },
  'open-pipeline-by-stage': {
    name: 'Open Pipeline by Stage',
    component: OpenPipelineByStage,
    usesBucketToggle: true,
    segmentation: {
      field: 'StageName',
      configurable: false,
      toggleable: false,
    },
    icon: <MdStackedBarChart color='#CC4E00' size={22} />,
    backgroundColor: '#FFDFB5',
    description:
      'Total value of open opportunities broken down by pipeline stage',
    usesStages: true,
    usesAmountField: true,
    cohortField: CLOSE_DATE,
  },
  'open-pipeline-by-rep': {
    name: 'Open Pipeline by Rep',
    component: OpenPipelineByRep,
    usesBucketToggle: false,
    segmentation: {
      field: 'ForecastCategory',
      configurable: true,
      toggleable: true,
    },
    icon: <BsFillPeopleFill color='#B42318' size={21} />,
    backgroundColor: '#FEE4E2',
    description:
      'Open opportunities expected to close in the current and next quarter and quota coverage per rep, grouped by team (if configured)',
    usesStages: false,
    usesAmountField: true,
    cohortField: OWNER_ID,
  },
  'new-pipeline-by-rep': {
    name: 'New Pipeline by Rep',
    component: NewPipelineByRep,
    usesBucketToggle: false,
    segmentation: {
      field: 'LeadSource',
      configurable: true,
      toggleable: true,
    },
    icon: <BsFillPeopleFill color='#B42318' size={21} />,
    backgroundColor: '#FEE4E2',
    description:
      'Total value of new opportunities per rep, grouped by team (if configured)',
    usesStages: false,
    usesAmountField: true,
    cohortField: OWNER_ID,
  },
  'total-closed-won-deals-and-amount': {
    name: 'Total Closed Won Deals and Amount',
    component: TotalClosedWon,
    usesBucketToggle: false,
    icon: <FaDollarSign color='#208368' size={20} />,
    backgroundColor: '#D6F1E3',
    description: 'Total value and count of closed won deals',
    usesStages: false,
    usesAmountField: true,
    cohortField: CLOSE_DATE,
  },
} as const

export { availableMetrics }
