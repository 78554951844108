import { LabelledValue } from '../Types'
import { isEmpty } from '../Utilities'
import * as uuid from 'uuid'

export type SalesforceField = {
  type: string
  name: string
  label: string
  picklist_values?: string[]
}

export class FilterInputValues {
  stringValue: string | null
  numberValue: number | null
  dateRangeValue: LabelledValue | null
  comparatorValue: LabelledValue | null
  multilistCombinationValue: MultiListCombination | null
  fieldValues: LabelledValue[]

  constructor(
    stringValue: string | null = null,
    numberValue: number | null = null,
    dateRangeValue: RelativeDateRange | null = null,
    comparatorValue: LabelledValue | null = null,
    multilistCombinationValue: MultiListCombination | null = null,
    fieldValues: LabelledValue[] = [],
  ) {
    this.stringValue = stringValue
    this.numberValue = numberValue
    this.dateRangeValue = dateRangeValue
      ? { label: dateRangeValue, value: dateRangeValue }
      : null
    this.comparatorValue = comparatorValue
    this.multilistCombinationValue = multilistCombinationValue
    this.fieldValues = fieldValues
  }

  isEmpty() {
    return (
      isEmpty(this.stringValue) &&
      isEmpty(this.numberValue) &&
      isEmpty(this.dateRangeValue) &&
      (isEmpty(this.fieldValues) ||
        this.fieldValues.every(f => f.value === 'all'))
    )
  }
}

export class ComparatorBase {
  label: string = ''
  symbol: string = ''
  get selectOption() {
    return {
      label: `${this.label} (${this.symbol})`,
      value: this,
    }
  }

  static fromString(str: string) {
    switch (str) {
      case 'LessThan':
        return new LessThan()
      case 'GreaterThan':
        return new GreaterThan()
      case 'EqualTo':
        return new EqualTo()
      default:
        throw new Error(`Unknown comparator: ${str}`)
    }
  }
}
export class LessThan extends ComparatorBase {
  label = 'Less than'
  symbol = '<'
  compare(a: any, b: any) {
    return a < b
  }
}

export class GreaterThan extends ComparatorBase {
  label = 'Greater than'
  symbol = '>'
  compare(a: any, b: any) {
    return a > b
  }
}

export class EqualTo extends ComparatorBase {
  label = 'Equal to'
  symbol = '='
  compare(a: any, b: any) {
    return a === b
  }
}

export type Comparator = LessThan | GreaterThan | EqualTo

export enum RelativeDateRange {
  LAST_QUARTER = 'Previous Fiscal Quarter',
  LAST_QUARTER_AND_THIS_QUARTER = 'Previous and Current Fiscal Quarters',
  THIS_QUARTER = 'Current Fiscal Quarter',
  THIS_QUARTER_AND_NEXT_QUARTER = 'Current and Next Fiscal Quarters',
  NEXT_QUARTER = 'Next Fiscal Quarter',
  LAST_YEAR = 'Previous Fiscal Year',
  LAST_YEAR_AND_THIS_YEAR = 'Previous and Current Fiscal Years',
  THIS_YEAR = 'Current Fiscal Year',
  THIS_YEAR_AND_NEXT_YEAR = 'Current and Next Fiscal Years',
  NEXT_YEAR = 'Next Fiscal Year',
}

export class AnyOf {
  label = 'any of'
  value = 'any'
}
export class AllOf {
  label = 'all of'
  value = 'all'
}
export type MultiListCombination = AnyOf | AllOf

function fiscalMonthOffset(fiscalYearStartMonth: number, currentMonth: number) {
  return (currentMonth - fiscalYearStartMonth + 12) % 12
}

class FiscalYear {
  fiscalYearStartMonth: number
  account_id: string
  today: Date

  constructor(fiscalYearStartMonth: number, account_id: string) {
    // Setting in Synch is 1-indexed, so we need to subtract 1
    this.fiscalYearStartMonth = fiscalYearStartMonth - 1
    this.account_id = account_id
    this.today = new Date()
  }

  getQuarter(offsetFromCurrent: number) {
    const today = this.today
    const currentMonth = today.getMonth()
    const fiscalYearOffset = fiscalMonthOffset(
      this.fiscalYearStartMonth,
      currentMonth,
    )
    const offsetWithinQuarter = fiscalYearOffset % 3
    const quarterStartMonth =
      currentMonth - offsetWithinQuarter + 3 * offsetFromCurrent
    const quarterEndMonth = quarterStartMonth + 2

    const quarter = Math.floor(fiscalYearOffset / 3) + 1 // 1-indexed, so 1, 2, 3 or 4
    const quarterMonthLookup = [
      'J',
      'F',
      'M',
      'A',
      'M',
      'J',
      'J',
      'A',
      'S',
      'O',
      'N',
      'D',
    ]

    // Making quarter bounds UTC as it's compared to Salesforce date which is in UTC
    return {
      start: new Date(Date.UTC(today.getUTCFullYear(), quarterStartMonth, 1)),
      end: new Date(
        Date.UTC(today.getUTCFullYear(), quarterEndMonth + 1, 0, 23, 59, 59),
      ),
      quarter: quarter,
      monthsInQuarter: [
        quarterMonthLookup[quarterStartMonth],
        quarterMonthLookup[(quarterStartMonth + 1) % 12],
        quarterMonthLookup[(quarterStartMonth + 2) % 12],
      ],
    }
  }
  getCurrentQuarter() {
    return this.getQuarter(0)
  }
  getLastQuarter() {
    if (
      this.account_id === '398545ac-831a-4d8c-a147-7b06eb3e43e0' &&
      this.today >= new Date('2025-02-01 00:00:00') &&
      this.today <= new Date('2025-04-30 23:59:59')
    ) {
      return {
        start: new Date(Date.UTC(2024, 9, 1, 0, 0, 0)), // Oct 1, 2024
        end: new Date(Date.UTC(2025, 0, 31, 23, 59, 59)), // Jan 31, 2025
        quarter: 4,
        monthsInQuarter: ['O', 'N', 'D', 'J'],
      }
    }

    return this.getQuarter(-1)
  }
  getNextQuarter() {
    return this.getQuarter(1)
  }

  getYear(offsetFromCurrent: number) {
    const today = this.today
    const currentMonth = today.getMonth()
    const fiscalYearOffset = fiscalMonthOffset(
      this.fiscalYearStartMonth,
      currentMonth,
    )
    const yearStartMonth =
      currentMonth - fiscalYearOffset + 12 * offsetFromCurrent
    const yearEndMonth = yearStartMonth + 11

    // Making year bounds UTC as it's compared to Salesforce date which is in UTC
    return {
      start: new Date(
        Date.UTC(today.getUTCFullYear(), yearStartMonth, 1, 0, 0, 0),
      ),
      end: new Date(
        Date.UTC(today.getUTCFullYear(), yearEndMonth + 1, 0, 23, 59, 59),
      ),
    }
  }

  getCurrentYear() {
    return this.getYear(0)
  }

  getLastYear() {
    if (
      this.account_id === '398545ac-831a-4d8c-a147-7b06eb3e43e0' &&
      this.today >= new Date('2025-02-01 00:00:00') &&
      this.today <= new Date('2026-01-31 23:59:59')
    ) {
      return {
        start: new Date(Date.UTC(2024, 0, 1, 0, 0, 0)), // Jan 1, 2024
        end: new Date(Date.UTC(2025, 0, 31, 23, 59, 59)), // Jan 31, 2025
      }
    }
    return this.getYear(-1)
  }

  getNextYear() {
    return this.getYear(1)
  }
}

export function isDateInRelativeDateRange(
  inputDate: string | Date,
  relativeDate: RelativeDateRange,
  fiscalYearStartMonth: number = 1,
  account_id: string,
) {
  const fiscalYear = new FiscalYear(fiscalYearStartMonth, account_id)
  const date = new Date(inputDate)
  if (relativeDate === RelativeDateRange.LAST_QUARTER) {
    const lastQuarter = fiscalYear.getLastQuarter()
    return date >= lastQuarter.start && date <= lastQuarter.end
  } else if (relativeDate === RelativeDateRange.LAST_QUARTER_AND_THIS_QUARTER) {
    const lastQuarter = fiscalYear.getLastQuarter()
    const currentQuarter = fiscalYear.getCurrentQuarter()
    return date >= lastQuarter.start && date <= currentQuarter.end
  } else if (relativeDate === RelativeDateRange.THIS_QUARTER) {
    const currentQuarter = fiscalYear.getCurrentQuarter()
    return date >= currentQuarter.start && date <= currentQuarter.end
  } else if (relativeDate === RelativeDateRange.THIS_QUARTER_AND_NEXT_QUARTER) {
    const currentQuarter = fiscalYear.getCurrentQuarter()
    const nextQuarter = fiscalYear.getNextQuarter()
    return date >= currentQuarter.start && date <= nextQuarter.end
  } else if (relativeDate === RelativeDateRange.NEXT_QUARTER) {
    const nextQuarter = fiscalYear.getNextQuarter()
    return date >= nextQuarter.start && date <= nextQuarter.end
  } else if (relativeDate === RelativeDateRange.LAST_YEAR) {
    const lastYear = fiscalYear.getLastYear()
    return date >= lastYear.start && date <= lastYear.end
  } else if (relativeDate === RelativeDateRange.LAST_YEAR_AND_THIS_YEAR) {
    const currentYear = fiscalYear.getCurrentYear()
    const lastYear = fiscalYear.getLastYear()
    return date >= lastYear.start && date <= currentYear.end
  } else if (relativeDate === RelativeDateRange.THIS_YEAR) {
    const currentYear = fiscalYear.getCurrentYear()
    return date >= currentYear.start && date <= currentYear.end
  } else if (relativeDate === RelativeDateRange.THIS_YEAR_AND_NEXT_YEAR) {
    const currentYear = fiscalYear.getCurrentYear()
    const nextYear = fiscalYear.getNextYear()
    return date >= currentYear.start && date <= nextYear.end
  } else if (relativeDate === RelativeDateRange.NEXT_YEAR) {
    const nextYear = fiscalYear.getNextYear()
    return date >= nextYear.start && date <= nextYear.end
  }
}

export class Filter {
  field: SalesforceField
  field_values: LabelledValue[]
  id: string
  comparator?: Comparator
  relativeDate?: RelativeDateRange
  multilistCombination?: MultiListCombination
  constructor(
    field: SalesforceField,
    field_values: LabelledValue[],
    id: string,
    comparator?: Comparator,
    relativeDate?: RelativeDateRange,
    multilistCombination?: MultiListCombination,
  ) {
    this.field = field
    this.field_values = field_values
    this.id = id
    this.comparator = comparator
    this.relativeDate = relativeDate
    this.multilistCombination = multilistCombination
  }

  renderComparator() {
    if (this.field.type === 'multipicklist' && this.multilistCombination) {
      return `${this.multilistCombination} of`
    }
    if (this.comparator?.symbol) {
      return this.comparator.symbol
    }
    if (['string', 'textarea'].includes(this.field.type)) {
      return ' contains '
    }
    return ' is '
  }

  render() {
    const label = this.field.label
    if (this.field_values.length > 1) {
      if (this.field.type === 'multipicklist' && this.multilistCombination) {
        return `${label} ${this.multilistCombination} of ${this.field_values.length} selected`
      }
      return `${label} (${this.field_values.length})`
    }
    const comparator = this.renderComparator()
    const value = this.relativeDate ?? this.field_values[0].label

    return `${label} ${comparator} ${value}`
  }

  static parseFilterString = (
    str: string | undefined,
    fields: any[] | undefined,
  ): Record<string, Filter> => {
    if (!str || !fields) {
      return {}
    }
    const filters: Record<string, Filter> = {}
    str
      .split('&')
      .map(stringFilter => {
        const [fieldName, value] = stringFilter.split('=')
        const field = fields?.find(
          f => f.name.toLowerCase() === fieldName.toLowerCase(),
        )

        switch (field?.type) {
          case 'boolean':
            return new Filter(
              field,
              [{ value: value, label: value === 'true' ? 'True' : 'False' }],
              uuid.v4(),
              undefined,
              undefined,
              undefined,
            )
          case 'number':
          case 'currency':
          case 'percent':
          case 'double':
          case 'int':
            const [comparator, numberValue] = value.split(':')
            return new Filter(
              field,
              [{ value: numberValue, label: numberValue }],
              uuid.v4(),
              ComparatorBase.fromString(comparator),
              undefined,
              undefined,
            )
          case 'date':
          case 'datetime':
            return new Filter(
              field,
              [{ value: value, label: value }],
              uuid.v4(),
              undefined,
              value as RelativeDateRange,
              undefined,
            )
          case 'multipicklist':
          case 'picklist':
          case 'reference':
            return new Filter(
              field,
              value.split(',').map(v => {
                const label = field.picklist_values.find(
                  (pv: any) => pv.value === v,
                )?.label
                return { value: v, label }
              }),
              uuid.v4(),
              undefined,
              undefined,
              undefined,
            )
          default:
            return null
        }
      })
      .forEach(f => {
        if (f) {
          filters[f.id] = f as Filter
        }
      })
    return filters
  }
}
