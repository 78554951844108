import styled from 'styled-components'
import {
  BucketType,
  CategorizedStageLabels,
  Opportunity,
  StageMetadata,
} from './Types'

/**
 * Generates a cohort name string based on a given date and bucket type.
 * Handles both monthly and quarterly cohort formats.
 *
 * @param date - The date string in 'YYYY-MM-DD' format from which to derive the cohort.
 * @param bucket - The bucket type ('month' or 'quarter') determining the cohort format.
 * @param fiscalYearStartMonth - The month (1-12) when the fiscal year starts, defaulting to January (1).
 *
 * @returns The cohort name string:
 *   - For months: "YYYY-MM" (e.g., "2023-03")
 *   - For quarters: "YYYY-Q#" (e.g., "2023-Q1")
 *   - For unsupported buckets: throws an error
 */
export function cohortNameFromDate(
  date: string,
  bucket: BucketType,
  fiscalYearStartMonth = 1,
  account_id: string,
) {
  const dateObj = new Date(date)
  const calendarYear = dateObj.getUTCFullYear()
  const calendarMonth = dateObj.getUTCMonth() // 0-based month (0-11)
  let fiscalStartMonth = fiscalYearStartMonth - 1 // 0-based month (0-11)

  switch (bucket) {
    case 'month':
      return `${calendarYear}-${String(calendarMonth + 1).padStart(2, '0')}`
    case 'quarter': {
      // TODO: let's have a custom handling for Haus here
      // if date is in Q4 (Oct 24, Nov 24, Jan 25) then return a hardcoded answer
      // if before then, calculate with fiscalStartMonth = 1
      // if after, handle normally
      if (account_id === '398545ac-831a-4d8c-a147-7b06eb3e43e0') {
        if (
          dateObj >= new Date(Date.UTC(2024, 9, 1, 0, 0, 0)) && // Oct 1, 2024
          dateObj <= new Date(Date.UTC(2025, 0, 31, 23, 59, 59)) // Jan 31, 2025
        ) {
          return '2024-Q4'
        } else if (dateObj) {
          fiscalStartMonth = 0 // For 2024 and earlier, it was a Jan start
        }
      }
      const fiscalYear =
        calendarMonth < fiscalStartMonth ? calendarYear - 1 : calendarYear
      const fiscalMonth = (calendarMonth - fiscalStartMonth + 12) % 12
      const fiscalQuarter = Math.floor(fiscalMonth / 3) + 1
      return `${fiscalYear}-Q${fiscalQuarter}`
    }
    default:
      throw new Error(
        `Unsupported bucket type: ${bucket}. Expected 'month' or 'quarter'.`,
      )
  }
}

/**
 * Formats a cohort string into a human-readable format based on the bucket type.
 * Handles cohort strings generated by cohortNameFromDate().
 * @param cohort - The cohort string to format
 *   - For months: "2023-03" (YYYY-MM format, from cohortNameFromDate)
 *   - For quarters: "2023-Q1" (YYYY-Q# format, from cohortNameFromDate)
 * @param bucket - The bucket type ('month' or 'quarter'), matching cohortNameFromDate options
 * @returns The formatted cohort string
 *   - For months: "Mar '23"
 *   - For quarters: "FY23 Q1"
 *   - For invalid buckets: returns the original cohort string
 */
export function renderCohortName(cohort: string, bucket: BucketType) {
  switch (bucket) {
    case 'month': {
      const date = new Date(`${cohort}-01 00:00:00`)
      const month = date.toLocaleString('default', { month: 'short' })
      const year = date.toLocaleString('default', { year: '2-digit' })
      return `${month} '${year}`
    }
    case 'quarter': {
      const [year, quarter] = cohort.split('-')
      const fiscalYear = year.slice(2)
      return `FY${fiscalYear} ${quarter}`
    }
    default:
      return cohort
  }
}

/**
 * Returns the number of days between two dates, ignoring the time component.
 * This will return a negative number if the end date is before the start date.
 * @param startDate - The start date in the format 'YYYY-MM-DD'.
 * @param endDate - The end date in the format 'YYYY-MM-DD'.
 * @returns The number of days between the two dates.
 */
export function daysBetweenDates(startDate: string, endDate: string) {
  const dt1 = new Date(startDate)
  const dt2 = new Date(endDate)

  const d1 = Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())
  const d2 = Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate())
  const ms = d2 - d1
  const days = ms / 1000 / 60 / 60 / 24
  return dt2 > dt1 ? Math.floor(days) : Math.ceil(days)
}

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
  justify-content: space-between;
`

export const SelectorsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  /* Automatically insert dividers between children using ::before on all but first child */
  & > *:not(:first-child)::before {
    content: '';
    position: absolute;
    left: -8px; /* Position in the middle of the gap */
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 2px; /* Increased from 1px to 2px */
    background-color: #cbd5e0; /* Changed from #e2e8f0 to a slightly darker gray */
  }

  /* Each child needs relative positioning for the absolute positioning of dividers */
  & > * {
    position: relative;
  }
`

export function getNumericValue(
  opp: Opportunity,
  fieldName: string,
): number | undefined {
  const value = opp[fieldName]
  if (typeof value === 'number') return value
  if (typeof value === 'string') {
    const parsed = parseFloat(value)
    return isNaN(parsed) ? undefined : parsed
  }
  return undefined
}

export function getStringValue(
  opp: Opportunity,
  fieldName: string,
  defaultValue: string = '(No Value)',
): string {
  const value = opp[fieldName]
  if (typeof value === 'string') return value
  if (value === null || value === undefined) return defaultValue
  return String(value)
}

export function categorizeDealStages(
  dealStages?: StageMetadata[],
): CategorizedStageLabels {
  if (!dealStages) {
    return {
      wonStageLabels: new Set<string>(),
      lostStageLabels: new Set<string>(),
      orderedOpenStageLabels: new Array<string>(),
    }
  }

  return dealStages.reduce(
    (acc, stage) => {
      if (stage.attributes.closed) {
        if (stage.attributes.won) {
          acc.wonStageLabels.add(stage.label)
        } else {
          acc.lostStageLabels.add(stage.label)
        }
      } else {
        acc.orderedOpenStageLabels.push(stage.label)
      }
      return acc
    },
    {
      wonStageLabels: new Set<string>(),
      lostStageLabels: new Set<string>(),
      orderedOpenStageLabels: new Array<string>(),
    },
  )
}

export function isEmpty(value: any): boolean {
  if (value === null || value === undefined) return true
  if (typeof value === 'string') return value.trim() === ''
  if (Array.isArray(value)) return value.length === 0
  if (typeof value === 'object') {
    return (
      Object.keys(value).length === 0 ||
      Object.values(value).every(v => isEmpty(v))
    )
  }
  return false
}
