import { Box, Icon } from '@chakra-ui/react'
import { MdZoomIn } from 'react-icons/md'

type CohortCellProps = {
    children: React.ReactNode
    onClick: () => void
    color?: string
}

export function CohortCell({ children, onClick, color }: CohortCellProps) {
    return (
        <Box
            fontSize="13px"
            display="flex"
            alignItems="center"
            cursor="pointer"
            gap="4px"
            _hover={{
                color: '#3D4BCF',
            }}
            onClick={onClick}
        >
            <Icon as={MdZoomIn} color="gray.500" boxSize={4} />
            {color && (
                <Box
                    display="inline-block"
                    w="10px"
                    h="10px"
                    bg={color}
                    mr="8px"
                />
            )}
            {children}
        </Box>
    )
} 