import React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.div<{ backgroundColor: string }>`
  height: 47px;
  width: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${props => props.backgroundColor};
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const Description = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #8d8d8d;
  margin-top: 4px;
`

interface MetricHeaderProps {
  icon: React.ReactNode
  backgroundColor: string
  name: string
  description: string
}

const MetricHeader: React.FC<MetricHeaderProps> = ({
  icon,
  backgroundColor,
  name,
  description,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
      <IconWrapper backgroundColor={backgroundColor}>{icon}</IconWrapper>
      <TitleContainer>
        <Title>{name}</Title>
        <Description>{description}</Description>
      </TitleContainer>
    </div>
  )
}

export default MetricHeader
