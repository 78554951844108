import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { getColorByIndex } from '../../../lib/colors'
import { CohortCell } from '../CohortCell'
import CohortDrillDown, { useCohortDrillDown } from '../CohortDrillDown'
import MetricHeader from '../MetricHeader'
import styles from '../pipelineAnalytics.module.css'
import {
  BaseMetricProps,
  BucketingProps,
  OpportunityHistory,
  StageMetadata,
} from '../Types'
import {
  HeaderContainer,
  categorizeDealStages,
  cohortNameFromDate,
  renderCohortName,
} from '../Utilities'
import { groupHistoryByOpportunity } from './DaysToReachXStageLogic'

type Props = BaseMetricProps & BucketingProps

function lastStageBeforeLost(
  history: OpportunityHistory[],
  lostStageLabels: Set<string>,
  mappedStageName: (stageName: string) => string,
) {
  const historyPerOpportunity = groupHistoryByOpportunity(history)

  const lastStageBeforeLostPerOpportunity = Object.entries(
    historyPerOpportunity,
  ).reduce((acc, [opportunityId, history]) => {
    const firstLostIndex = history.findIndex(h =>
      lostStageLabels.has(mappedStageName(h.StageName)),
    )
    if (firstLostIndex > 0) {
      const lastStageBeforeLost = mappedStageName(
        history[firstLostIndex - 1].StageName,
      )

      acc[opportunityId] = lastStageBeforeLost
    }
    return acc
  }, {} as Record<string, string>)

  return lastStageBeforeLostPerOpportunity
}

function DealsToReachXStage({
  data,
  fiscalYearStartMonth,
  bucketingScheme,
  salesforceInstanceUrl,
  name,
  icon,
  backgroundColor,
  description,
  accountId,
  cohortField,
}: Props) {
  const orderedDealStages: string[] = useMemo(() => {
    return data?.deal_stages.map((stage: StageMetadata) => stage.label) ?? []
  }, [data?.deal_stages])

  const dealStageIndex: Record<string, number> = data?.deal_stages
    ? orderedDealStages.reduce(
        (acc: Record<string, number>, stage: string, index: number) => {
          acc[stage] = index
          return acc
        },
        {},
      )
    : {}

  const { cohortsFound, rowsDeals, cumulativeRowsDeals, cohortOpportunities } =
    useMemo(() => {
      if (!data || !data.opportunities || !data.opportunity_history) {
        return {
          cohortsFound: [],
          rowsDeals: {},
          cumulativeRowsDeals: {},
          cohortOpportunities: {},
        }
      }

      function mappedStageName(stageName: string) {
        if (stageName in data.old_deal_stage_mapping) {
          return data.old_deal_stage_mapping[stageName]
        }
        return stageName
      }

      const { lostStageLabels } = categorizeDealStages(data.deal_stages)

      const lastStageBeforeLostPerOpportunity = lastStageBeforeLost(
        data.opportunity_history,
        lostStageLabels,
        mappedStageName,
      )

      // Process opportunities into cohort and deal stage counts
      const rowsDeals: Record<string, Record<string, number>> = {}
      const lostDeals: Record<string, Record<string, number>> = {}
      const cohortsFound = new Set<string>([])
      const cohortOpportunities: Record<string, any[]> = {}

      data.opportunities.forEach((opportunity: any) => {
        const dealStage = mappedStageName(opportunity.StageName)
        const cohort = cohortNameFromDate(
          opportunity[cohortField!.name] as string,
          bucketingScheme,
          fiscalYearStartMonth,
          accountId,
        )

        // Add to cohort opportunities map
        if (!cohortOpportunities[cohort]) {
          cohortOpportunities[cohort] = []
        }
        cohortOpportunities[cohort].push(opportunity)

        if (lostStageLabels.has(dealStage)) {
          const lastStageBeforeLost =
            lastStageBeforeLostPerOpportunity[opportunity.Id]
          if (!lostDeals[lastStageBeforeLost]) {
            lostDeals[lastStageBeforeLost] = {}
          }
          lostDeals[lastStageBeforeLost][cohort] =
            (lostDeals[lastStageBeforeLost][cohort] || 0) + 1
        }

        if (!rowsDeals[dealStage]) {
          rowsDeals[dealStage] = {}
        }
        rowsDeals[dealStage][cohort] = (rowsDeals[dealStage][cohort] || 0) + 1
        cohortsFound.add(cohort)
      })

      const sortedCohorts: Array<string> = Array.from(cohortsFound).sort()

      // Count cumulative totals for display in table
      const cumulativeRowsDeals: Record<string, Record<string, number>> = {}
      let previousRow: Record<string, number> = {}
      orderedDealStages
        .slice()
        .reverse()
        .forEach(dealStage => {
          // TODO: handle any loss stages
          if (lostStageLabels.has(dealStage)) {
            cumulativeRowsDeals[dealStage] = rowsDeals[dealStage]
            return
          }
          const currentRow = rowsDeals[dealStage]
          const dealsLostAtStage = lostDeals[dealStage]
          if (!(dealStage in cumulativeRowsDeals)) {
            cumulativeRowsDeals[dealStage] = {}
          }
          Array.from(cohortsFound).forEach(cohort => {
            cumulativeRowsDeals[dealStage][cohort] =
              (previousRow[cohort] || 0) +
              ((currentRow && currentRow[cohort]) || 0) +
              ((dealsLostAtStage && dealsLostAtStage[cohort]) || 0)
          })
          previousRow = cumulativeRowsDeals[dealStage]
        })

      return {
        cohortsFound: sortedCohorts,
        rowsDeals,
        cumulativeRowsDeals,
        cohortOpportunities,
      }
    }, [
      data,
      orderedDealStages,
      fiscalYearStartMonth,
      bucketingScheme,
      accountId,
      cohortField,
    ])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  )

  const chartData = {
    labels: Array.from(cohortsFound).map(cohort =>
      renderCohortName(cohort, bucketingScheme),
    ),
    datasets: orderedDealStages.map(dealStage => ({
      label: dealStage,
      data: Array.from(cohortsFound).map(cohort => {
        return (
          (rowsDeals && rowsDeals[dealStage] && rowsDeals[dealStage][cohort]) ||
          0
        )
      }),
      backgroundColor: getColorByIndex(
        dealStageIndex[dealStage],
        orderedDealStages.length,
      ),
    })),
  }
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  }

  const { selectedCohort, setSelectedCohort } = useCohortDrillDown(
    cohortField!,
    bucketingScheme,
  )

  return (
    <>
      <HeaderContainer>
        <MetricHeader
          icon={icon}
          backgroundColor={backgroundColor}
          name={name}
          description={description}
        />
      </HeaderContainer>
      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>

      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>{cohortField!.label}</Th>
              {orderedDealStages.map((dealStage, index) => (
                <Th
                  sx={{
                    textTransform: 'none',
                    letterSpacing: 'none',
                    color: '#71717a',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                  }}
                  key={dealStage}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: getColorByIndex(
                        index,
                        orderedDealStages.length,
                      ),
                      marginRight: '8px',
                    }}
                  ></span>
                  {dealStage}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {Array.from(cohortsFound).map(cohort => {
              return (
                <Tr key={cohort}>
                  <Td>
                    <CohortCell onClick={() => setSelectedCohort(cohort)}>
                      {renderCohortName(cohort, bucketingScheme)}
                    </CohortCell>
                  </Td>
                  {orderedDealStages.map(dealStage => (
                    <Td key={dealStage} textAlign='center'>
                      {cumulativeRowsDeals[dealStage]?.[cohort] ?? 0}
                    </Td>
                  ))}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </div>

      <CohortDrillDown
        selectedCohort={selectedCohort}
        opportunitiesByCohort={cohortOpportunities}
        cohortField={cohortField!.name}
        salesforceInstanceUrl={salesforceInstanceUrl}
        renderCohortName={cohort => renderCohortName(cohort, bucketingScheme)}
      />
    </>
  )
}
export default DealsToReachXStage
