import { useQuery } from '@tanstack/react-query'
import { createContext, useContext } from 'react'
import Api from '../../lib/api'
import { PipelineAnalyticsBackendResponse } from './Types'

const PipelineAnalyticsContext = createContext<{
  data: PipelineAnalyticsBackendResponse | undefined
  isLoading: boolean
}>({
  data: {
    opportunities: [],
    opportunity_history: [],
    start_date_of_last_financial_year: '',
    fields: [],
    deal_stages: [],
    old_deal_stage_mapping: {},
    record_types: [],
    deal_stages_per_record_type: {},
    data_last_fetched_at: '',
    users: [],
    teams: [],
  },
  isLoading: false,
})

async function getPipelineAnalytics() {
  const pipelineAnalytics = await Api.pipelineAnalytics.getPipelineAnalytics()
  const teams = await Api.teams.getTeams({})
  return {
    ...pipelineAnalytics,
    teams: teams.teams,
  }
}

function PipelineAnalyticsProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { data, isLoading } = useQuery({
    queryKey: ['pipelineAnalytics'],
    queryFn: () => getPipelineAnalytics(),
  })

  return (
    <PipelineAnalyticsContext.Provider
      value={{
        data,
        isLoading,
      }}
    >
      {children}
    </PipelineAnalyticsContext.Provider>
  )
}

const usePipelineAnalytics = () => {
  const context = useContext(PipelineAnalyticsContext)
  if (!context) {
    throw new Error(
      'usePipelineAnalytics must be used within a PipelineAnalyticsProvider',
    )
  }
  return context
}

export { PipelineAnalyticsProvider, usePipelineAnalytics }
