import { useMemo } from 'react'
import styled from 'styled-components'
import { formatAmount } from '../../../lib/dealUtils'
import CohortDrillDown from '../CohortDrillDown'
import FieldSelector from '../FieldSelector'
import MetricHeader from '../MetricHeader'
import { AmountFieldProps, BaseMetricProps, Opportunity } from '../Types'
import {
  getNumericValue,
  HeaderContainer,
  SelectorsContainer,
} from '../Utilities'

// Explicitly define the props type for this chart
type Props = BaseMetricProps & AmountFieldProps

// Styled components
const SummaryTile = styled.div`
  flex: 1;
  background-color: white;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const SummaryLabel = styled.div`
  font-size: 14px;
  color: #71717a;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const SummaryValue = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: #1e293b;
`

const SINGLE_COHORT = 'All Won Opportunities'

function TotalClosedWon({
  data,
  salesforceInstanceUrl,
  name,
  icon,
  backgroundColor,
  description,
  selectedAmountField,
  amountFields,
  onAmountFieldChange,
  cohortField,
}: Props) {
  const processedData = useMemo(() => {
    if (!data || !data.opportunities) {
      return {
        totalAmount: 0,
        totalDeals: 0,
        opportunitiesByCohort: {} as Record<string, Opportunity[]>,
        selectedCohort: null,
      }
    }

    const opportunities = data.opportunities
      .filter((opp: Opportunity) => opp.IsWon)
      .sort(
        (a: Opportunity, b: Opportunity) =>
          new Date(b.CloseDate).getTime() - new Date(a.CloseDate).getTime(),
      )

    const totalAmount = opportunities.reduce(
      (sum: number, opp: Opportunity) =>
        sum + (getNumericValue(opp, selectedAmountField) ?? 0),
      0,
    )

    const opportunitiesByCohort = {
      [SINGLE_COHORT]: opportunities,
    }

    return {
      totalAmount,
      totalDeals: opportunities.length,
      opportunitiesByCohort,
      selectedCohort: SINGLE_COHORT,
    }
  }, [data, selectedAmountField])

  return (
    <>
      <HeaderContainer>
        <MetricHeader
          icon={icon}
          backgroundColor={backgroundColor}
          name={name}
          description={description}
        />
        <SelectorsContainer>
          <FieldSelector
            type='amount'
            selectedField={selectedAmountField}
            availableFields={amountFields}
            onFieldChange={onAmountFieldChange}
          />
        </SelectorsContainer>
      </HeaderContainer>

      <div style={{ display: 'flex', gap: '24px', marginBottom: '48px' }}>
        <SummaryTile>
          <SummaryLabel>Total Deals Won</SummaryLabel>
          <SummaryValue>{processedData.totalDeals}</SummaryValue>
        </SummaryTile>
        <SummaryTile>
          <SummaryLabel>Total Amount Won</SummaryLabel>
          <SummaryValue>{formatAmount(processedData.totalAmount)}</SummaryValue>
        </SummaryTile>
      </div>

      {processedData.totalDeals > 0 && (
        <CohortDrillDown
          selectedCohort={processedData.selectedCohort}
          opportunitiesByCohort={processedData.opportunitiesByCohort}
          cohortField={cohortField!.name}
          salesforceInstanceUrl={salesforceInstanceUrl}
          renderCohortName={() => SINGLE_COHORT}
        />
      )}
    </>
  )
}

export default TotalClosedWon
