import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { getColorByIndex } from '../../../lib/colors'
import { CohortCell } from '../CohortCell'
import CohortDrillDown, { useCohortDrillDown } from '../CohortDrillDown'
import FieldSelector from '../FieldSelector'
import MetricHeader from '../MetricHeader'
import styles from '../pipelineAnalytics.module.css'
import {
  AmountFieldProps,
  BaseMetricProps,
  BucketingProps,
  Opportunity,
  SegmentationProps,
} from '../Types'
import {
  HeaderContainer,
  SelectorsContainer,
  categorizeDealStages,
  cohortNameFromDate,
  getStringValue,
  renderCohortName,
} from '../Utilities'

// Move registration outside component
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// Explicitly define the props type for this metric
type Props = BaseMetricProps &
  BucketingProps &
  SegmentationProps &
  AmountFieldProps

type ProcessedData = {
  cohorts: string[]
  channelCounts: Record<string, Record<string, number>>
  cohortOpportunities: Record<string, any[]>
}

function OpportunitiesCreatedByChannel({
  data,
  fiscalYearStartMonth,
  salesforceInstanceUrl,
  accountId,
  name,
  icon,
  backgroundColor,
  description,
  cohortField,
  bucketingScheme,
  showSegments,
  segmentationField,
  segmentationFieldConfigurable,
  segmentableFields,
  onSegmentationFieldChange,
}: Props) {
  const orderedStageLabels = useMemo(() => {
    if (data.deal_stages) {
      const categorizedStages = categorizeDealStages(data.deal_stages)
      return [
        ...Array.from(categorizedStages.orderedOpenStageLabels),
        ...Array.from(categorizedStages.wonStageLabels),
        ...Array.from(categorizedStages.lostStageLabels),
      ]
    }
    return null
  }, [data.deal_stages])

  const processedData = useMemo((): ProcessedData => {
    if (!data?.opportunities) {
      return {
        cohorts: [],
        channelCounts: {},
        cohortOpportunities: {},
      }
    }

    const cohortSet = new Set<string>()
    const channelCounts: Record<string, Record<string, number>> = {}
    const cohortOpportunities: Record<string, any[]> = {}

    data.opportunities.forEach((opp: Opportunity) => {
      const cohort = cohortNameFromDate(
        opp[cohortField!.name] as string,
        bucketingScheme,
        fiscalYearStartMonth,
        accountId,
      )
      const channel = getStringValue(opp, segmentationField)

      cohortSet.add(cohort)

      if (!channelCounts[channel]) {
        channelCounts[channel] = {}
      }

      channelCounts[channel][cohort] = (channelCounts[channel][cohort] || 0) + 1

      if (!cohortOpportunities[cohort]) {
        cohortOpportunities[cohort] = []
      }
      cohortOpportunities[cohort].push(opp)
    })

    return {
      cohorts: Array.from(cohortSet).sort(),
      channelCounts,
      cohortOpportunities,
    }
  }, [
    data,
    bucketingScheme,
    fiscalYearStartMonth,
    accountId,
    segmentationField,
    cohortField,
  ])

  const sortedChannels =
    segmentationField === 'StageName' && orderedStageLabels
      ? orderedStageLabels
      : Object.keys(processedData.channelCounts).sort()

  const chartData = {
    labels: processedData.cohorts.map(cohort =>
      renderCohortName(cohort, bucketingScheme),
    ),
    datasets: sortedChannels.map((channel, index) => ({
      label: channel,
      data: processedData.cohorts.map(
        cohort => processedData.channelCounts[channel][cohort] || 0,
      ),
      backgroundColor: getColorByIndex(index, sortedChannels.length),
    })),
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw || 0
            const opportunityText =
              value === 1 ? 'opportunity' : 'opportunities'
            return `${context.dataset.label}: ${value} ${opportunityText}`
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: cohortField!.label,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  }

  const { selectedCohort, setSelectedCohort } = useCohortDrillDown(
    cohortField!,
    bucketingScheme,
  )

  return (
    <>
      <HeaderContainer>
        <MetricHeader
          icon={icon}
          backgroundColor={backgroundColor}
          name={name}
          description={description}
        />
        <SelectorsContainer>
          {segmentationFieldConfigurable && (
            <FieldSelector
              type='segmentation'
              selectedField={segmentationField}
              availableFields={segmentableFields}
              onFieldChange={onSegmentationFieldChange}
            />
          )}
        </SelectorsContainer>
      </HeaderContainer>

      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>

      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>{cohortField!.label}</Th>
              {sortedChannels.map((channel, index) => (
                <Th key={channel}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: getColorByIndex(
                        index,
                        sortedChannels.length,
                      ),
                      marginRight: '8px',
                    }}
                  ></span>
                  {channel}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {processedData.cohorts.map(cohort => (
              <Tr key={cohort}>
                <Td>
                  <CohortCell onClick={() => setSelectedCohort(cohort)}>
                    {renderCohortName(cohort, bucketingScheme)}
                  </CohortCell>
                </Td>
                {sortedChannels.map(channel => (
                  <Td key={channel}>
                    {processedData.channelCounts[channel][cohort] || 0}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>

      <CohortDrillDown
        selectedCohort={selectedCohort}
        opportunitiesByCohort={processedData.cohortOpportunities}
        cohortField={cohortField!.name}
        salesforceInstanceUrl={salesforceInstanceUrl}
        renderCohortName={cohort => renderCohortName(cohort, bucketingScheme)}
      />
    </>
  )
}

export default OpportunitiesCreatedByChannel
