import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { getColorByIndex } from '../../../lib/colors'
import { formatAmount } from '../../../lib/dealUtils'
import { CohortCell } from '../CohortCell'
import CohortDrillDown, { useCohortDrillDown } from '../CohortDrillDown'
import FieldSelector from '../FieldSelector'
import MetricHeader from '../MetricHeader'
import styles from '../pipelineAnalytics.module.css'
import {
  AmountFieldProps,
  BaseMetricProps,
  BucketType,
  BucketingProps,
  Opportunity,
} from '../Types'
import {
  HeaderContainer,
  SelectorsContainer,
  cohortNameFromDate,
  getNumericValue,
  renderCohortName,
} from '../Utilities'

type Props = BaseMetricProps & BucketingProps & AmountFieldProps

type CohortData = {
  sum: number
  count: number
}

const calculateAverages = (cohortTotals: Record<string, CohortData>) => {
  return Object.entries(cohortTotals).reduce(
    (acc, [cohort, { sum, count }]) => {
      acc[cohort] = count > 0 ? sum / count : 0
      return acc
    },
    {} as Record<string, number>,
  )
}

const getChartData = (
  cohortsFound: string[],
  cohortAverages: Record<string, number>,
  bucketingScheme: BucketType,
) => {
  const datasets = [
    {
      label: 'Average Sale Price',
      data: cohortsFound.map(cohort => cohortAverages[cohort] || 0),
      backgroundColor: getColorByIndex(0, 1),
    },
  ]

  const labels = cohortsFound.map(cohort =>
    renderCohortName(cohort, bucketingScheme),
  )

  return { labels, datasets }
}

function AverageSalePrice({
  // Base props
  data,
  fiscalYearStartMonth,
  salesforceInstanceUrl,
  accountId,
  name,
  icon,
  backgroundColor,
  description,
  cohortField,
  // From BucketingProps
  bucketingScheme,
  // From AmountFieldProps
  selectedAmountField,
  amountFields,
  onAmountFieldChange,
}: Props) {
  const {
    cohortsFound,
    cohortAverages,
    cohortCounts,
    cohortOpportunities,
  }: {
    cohortsFound: string[]
    cohortAverages: Record<string, number>
    cohortCounts: Record<string, number>
    cohortOpportunities: Record<string, Opportunity[]>
  } = useMemo(() => {
    if (!data?.opportunities) {
      return {
        cohortsFound: [],
        cohortAverages: {},
        cohortCounts: {},
        cohortOpportunities: {},
      }
    }

    const totals: Record<string, CohortData> = {}
    const opportunitiesByCohort: Record<string, Opportunity[]> = {}

    data.opportunities.forEach((opportunity: Opportunity) => {
      if (!opportunity.IsWon) return

      const cohort = cohortNameFromDate(
        opportunity[cohortField!.name] as string,
        bucketingScheme,
        fiscalYearStartMonth,
        accountId,
      )
      if (!totals[cohort]) {
        totals[cohort] = { sum: 0, count: 0 }
      }
      const amount = getNumericValue(opportunity, selectedAmountField) ?? 0
      totals[cohort].sum += amount
      totals[cohort].count += 1

      // Store opportunities by cohort
      if (!opportunitiesByCohort[cohort]) {
        opportunitiesByCohort[cohort] = []
      }
      opportunitiesByCohort[cohort].push(opportunity)
    })

    return {
      cohortsFound: Object.keys(totals).sort(),
      cohortAverages: calculateAverages(totals),
      cohortCounts: Object.fromEntries(
        Object.entries(totals).map(([cohort, { count }]) => [cohort, count]),
      ),
      cohortOpportunities: opportunitiesByCohort,
    }
  }, [
    data,
    bucketingScheme,
    fiscalYearStartMonth,
    selectedAmountField,
    accountId,
    cohortField,
  ])

  const { selectedCohort, setSelectedCohort } = useCohortDrillDown(
    cohortField!,
    bucketingScheme,
  )

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
  )

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return formatAmount(context.raw).toString()
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: cohortField!.label,
        },
      },
      y: {
        grid: {
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          callback: function (this: any, tickValue: number | string) {
            return formatAmount(Number(tickValue)).toString()
          },
        },
      },
    },
  }

  const chartData = getChartData(cohortsFound, cohortAverages, bucketingScheme)

  return (
    <>
      <HeaderContainer>
        <MetricHeader
          icon={icon}
          backgroundColor={backgroundColor}
          name={name}
          description={description}
        />
        <SelectorsContainer>
          <FieldSelector
            type='amount'
            selectedField={selectedAmountField}
            availableFields={amountFields}
            onFieldChange={onAmountFieldChange}
          />
        </SelectorsContainer>
      </HeaderContainer>
      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table
          className={styles.fixedFirstColumn}
          variant='simple'
          size='sm'
          style={{ width: 'auto', minWidth: '50%' }}
        >
          <Thead>
            <Tr>
              <Th>{cohortField!.label}</Th>
              <Th textAlign='right'>Amount</Th>
              <Th textAlign='center'>Number of Deals</Th>
            </Tr>
          </Thead>
          <Tbody>
            {cohortsFound.map(cohort => (
              <Tr key={cohort}>
                <Td>
                  <CohortCell onClick={() => setSelectedCohort(cohort)}>
                    {renderCohortName(cohort, bucketingScheme)}
                  </CohortCell>
                </Td>
                <Td textAlign='right'>
                  {formatAmount(cohortAverages[cohort] || 0, 2)}
                </Td>
                <Td textAlign='center'>{cohortCounts[cohort]}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>

      <CohortDrillDown
        selectedCohort={selectedCohort}
        opportunitiesByCohort={cohortOpportunities}
        cohortField={cohortField!.name}
        salesforceInstanceUrl={salesforceInstanceUrl}
        renderCohortName={cohort => renderCohortName(cohort, bucketingScheme)}
      />
    </>
  )
}

export default AverageSalePrice
