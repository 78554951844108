import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { getColorByIndex, makeHexLighter } from '../../../lib/colors'
import { formatAmount } from '../../../lib/dealUtils'
import { CohortCell } from '../CohortCell'
import CohortDrillDown, { useCohortDrillDown } from '../CohortDrillDown'
import FieldSelector from '../FieldSelector'
import {
  isDateInRelativeDateRange,
  RelativeDateRange,
} from '../Filtering/FilterService'
import MetricHeader from '../MetricHeader'
import styles from '../pipelineAnalytics.module.css'
import {
  AmountFieldProps,
  BaseMetricProps,
  BucketingProps,
  Opportunity,
  SegmentationProps,
} from '../Types'
import {
  categorizeDealStages,
  getNumericValue,
  getStringValue,
  HeaderContainer,
  SelectorsContainer,
} from '../Utilities'

const THIS_QUARTER = 'this'
const NEXT_QUARTER = 'next'

// Explicitly define the props type for this metric
type Props = BaseMetricProps &
  AmountFieldProps &
  BucketingProps &
  SegmentationProps

function OpenPipelineByRep({
  data,
  fiscalYearStartMonth,
  salesforceInstanceUrl,
  accountId,
  name,
  icon,
  backgroundColor,
  description,
  cohortField,
  bucketingScheme,
  selectedAmountField,
  amountFields,
  onAmountFieldChange,
  showSegments,
  segmentationField,
  segmentationFieldConfigurable,
  segmentableFields,
  onSegmentationFieldChange,
}: Props) {
  const orderedStageLabels = useMemo(() => {
    if (data.deal_stages) {
      return Array.from(
        categorizeDealStages(data.deal_stages).orderedOpenStageLabels,
      )
    }
    return null
  }, [data.deal_stages])

  const processedData = useMemo(() => {
    if (!data || !data.opportunities) {
      return {
        reps: [],
        pipelineThisQuarter: {},
        pipelineNextQuarter: {},
        repNames: {},
        closedWonThisQuarter: {},
        repOpportunities: {},
        pipelineThisQuarterBySegment: {},
        pipelineNextQuarterBySegment: {},
        segments: new Set<string>(),
      }
    }

    const pipelineThisQuarter: Record<string, number> = {}
    const pipelineNextQuarter: Record<string, number> = {}
    const repNames: Record<string, string> = {}
    const closedWonThisQuarter: Record<string, number> = {}
    const repOpportunities: Record<string, Opportunity[]> = {}
    const pipelineThisQuarterBySegment: Record<
      string,
      Record<string, number>
    > = {}
    const pipelineNextQuarterBySegment: Record<
      string,
      Record<string, number>
    > = {}
    const segments = new Set<string>()

    data.opportunities.forEach((opp: Opportunity) => {
      const repId = opp.OwnerId
      const repName = opp.Owner.Name
      const amount = getNumericValue(opp, selectedAmountField) ?? 0
      const segment = getStringValue(opp, segmentationField)
      repNames[repId] = repName

      const inThisQuarter = isDateInRelativeDateRange(
        opp.CloseDate,
        RelativeDateRange.THIS_QUARTER,
        fiscalYearStartMonth,
        accountId,
      )
      const inNextQuarter = isDateInRelativeDateRange(
        opp.CloseDate,
        RelativeDateRange.NEXT_QUARTER,
        fiscalYearStartMonth,
        accountId,
      )

      if (!opp.IsClosed && (inThisQuarter || inNextQuarter)) {
        segments.add(segment)

        // Initialize rep opportunities array if needed
        if (!repOpportunities[repId]) {
          repOpportunities[repId] = []
        }
        repOpportunities[repId].push(opp)

        // Determine which quarter's data to update
        const targetQuarter = inThisQuarter ? 'ThisQuarter' : 'NextQuarter'
        const pipelineMap =
          targetQuarter === 'ThisQuarter'
            ? pipelineThisQuarter
            : pipelineNextQuarter
        const pipelineBySegmentMap =
          targetQuarter === 'ThisQuarter'
            ? pipelineThisQuarterBySegment
            : pipelineNextQuarterBySegment

        // Update pipeline totals
        pipelineMap[repId] = (pipelineMap[repId] || 0) + amount

        // Update segment-specific totals
        if (!pipelineBySegmentMap[repId]) {
          pipelineBySegmentMap[repId] = {}
        }
        pipelineBySegmentMap[repId][segment] =
          (pipelineBySegmentMap[repId][segment] || 0) + amount
      } else if (opp.IsWon && inThisQuarter) {
        closedWonThisQuarter[repId] =
          (closedWonThisQuarter[repId] || 0) + amount
      }
    })

    const reps = Object.keys(pipelineThisQuarter).sort()

    return {
      reps,
      pipelineThisQuarter,
      pipelineNextQuarter,
      repNames,
      closedWonThisQuarter,
      repOpportunities,
      pipelineThisQuarterBySegment,
      pipelineNextQuarterBySegment,
      segments,
    }
  }, [
    data,
    fiscalYearStartMonth,
    accountId,
    selectedAmountField,
    segmentationField,
  ])

  const { selectedCohort: selectedRep, setSelectedCohort: setSelectedRep } =
    useCohortDrillDown(cohortField!, bucketingScheme)

  const teams = (data?.teams ?? []).filter(
    (team: any) => team.team_members?.length > 0,
  )
  const groupByTeams = teams.length > 1

  function flatMapTeamMembers(
    teams: any[],
    callback: (
      team: any,
      member: any,
      team_index: number,
      member_index: number,
    ) => any,
  ) {
    return (
      teams
        .map((team: any, team_index: number) => {
          return team.team_members.map((member: any, member_index: number) => {
            return callback(team, member, team_index, member_index)
          })
        })
        .flat() ?? []
    )
  }

  // get quotas for each team
  // keep 1 quota per rep per team - prefer period_type=quarterly and the most recently updated
  // store by salesforce_org_member_id and salesforce_user_id
  const quotas = useMemo(() => {
    return flatMapTeamMembers(teams, (team: any, member: any, _3, _4) => {
      // this will sort quarterly to the front and later updated_at to the front
      // therefore this can return the first quota in the sorted list
      const sortedQuotas = (member.quotas ?? []).sort((a: any, b: any) => {
        if (a.period_type === b.period_type) {
          return (
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
          )
        }
        return b.period_type.localeCompare(a.period_type)
      })
      if (sortedQuotas.length > 0) {
        return {
          ...sortedQuotas[0],
          repId: member.salesforce_user_id,
          teamId: team.id,
        }
      }
      return null
    })
  }, [teams])

  const quotasByRep = quotas.reduce((acc: any, quota: any) => {
    if (quota?.repId) {
      acc[quota.repId] = quota
    }
    return acc
  }, {})
  const quotasByRepAndTeam = quotas.reduce((acc: any, quota: any) => {
    if (quota?.repId && quota?.teamId) {
      if (!(quota?.repId in acc)) {
        acc[quota.repId] = {}
      }
      acc[quota.repId][quota.teamId] = quota
    }
    return acc
  }, {})

  function pipelineThisQuarter(salesforceUserId: string) {
    return processedData.pipelineThisQuarter[salesforceUserId] || 0
  }

  function pipelineNextQuarter(salesforceUserId: string) {
    return processedData.pipelineNextQuarter[salesforceUserId] || 0
  }

  function closedWonThisQuarter(salesforceUserId: string) {
    return processedData.closedWonThisQuarter[salesforceUserId] || 0
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  )

  const orderedSegments =
    segmentationField === 'StageName' && orderedStageLabels
      ? orderedStageLabels
      : Array.from(processedData.segments)

  const justRepsChartData = {
    labels: processedData.reps.map(repId => processedData.repNames[repId]),
    datasets: (() => {
      return showSegments
        ? [
            ...orderedSegments.map((segment, index) => ({
              label: `This Quarter • ${segment}`,
              data: processedData.reps.map(repId => ({
                rep: processedData.repNames[repId],
                pipeline:
                  processedData.pipelineThisQuarterBySegment[repId]?.[
                    segment
                  ] || 0,
                closed: closedWonThisQuarter(repId),
                quota: quotasByRep[repId]?.amount,
              })),
              backgroundColor: makeHexLighter(
                getColorByIndex(0, 2),
                index * 20,
              ),
            })),
            ...orderedSegments.map((segment, index) => ({
              label: `Next Quarter • ${segment}`,
              data: processedData.reps.map(repId => ({
                rep: processedData.repNames[repId],
                pipeline:
                  processedData.pipelineNextQuarterBySegment[repId]?.[
                    segment
                  ] || 0,
                closed: closedWonThisQuarter(repId),
                quota: quotasByRep[repId]?.amount,
              })),
              backgroundColor: makeHexLighter(
                getColorByIndex(1, 2),
                index * 20,
              ),
            })),
          ]
        : [
            {
              label: 'This Quarter',
              data: processedData.reps.map(repId => ({
                rep: processedData.repNames[repId],
                pipeline: pipelineThisQuarter(repId),
                closed: closedWonThisQuarter(repId),
                quota: quotasByRep[repId]?.amount,
              })),
              backgroundColor: processedData.reps.map((_, index) =>
                getColorByIndex(index, processedData.reps.length),
              ),
            },
            {
              label: 'Next Quarter',
              data: processedData.reps.map(repId => ({
                rep: processedData.repNames[repId],
                pipeline: pipelineNextQuarter(repId),
                closed: closedWonThisQuarter(repId),
                quota: quotasByRep[repId]?.amount,
              })),
              backgroundColor: processedData.reps.map((_, index) =>
                makeHexLighter(
                  getColorByIndex(index, processedData.reps.length),
                  70,
                ),
              ),
            },
          ]
    })(),
  }

  // Custom plugin to render Remaining Quota lines
  const quotaIndicatorPlugin = {
    id: 'quotaIndicator',
    afterDatasetsDraw(chart: any) {
      const {
        ctx,
        scales: { x, y },
      } = chart

      const datasetMeta = chart.getDatasetMeta(0)

      chart.data.datasets[0].data.forEach((value: any, index: any) => {
        if (!value || !value.quota) {
          return
        }
        const quota = value.quota
        const closed = value.closed ?? 0

        const remainingQuota = Math.max(quota - closed, 0)
        const barX = x.getPixelForValue(index) // X position of bar
        const goalY = y.getPixelForValue(remainingQuota) // Y position for goal

        const bar = datasetMeta.data[index] // Get the bar for this index
        const lineWidth = bar.width / 2 + 2 // The actual bar width

        // Draw the goal indicator as a small horizontal line
        ctx.save()
        ctx.beginPath()
        ctx.strokeStyle = 'black' // Goal line color
        ctx.lineWidth = 3
        ctx.moveTo(barX - lineWidth, goalY) // Left endpoint
        ctx.lineTo(barX + lineWidth, goalY) // Right endpoint
        ctx.stroke()
        ctx.restore()
      })
    },
  }

  const teamGroupedChartData = {
    labels: flatMapTeamMembers(
      teams,
      (_1, member: any, _2, _3) =>
        processedData.repNames[member.salesforce_user_id] ?? null,
    ),
    datasets: (() => {
      const createRepData = (
        repId: string,
        pipeline: number,
        team: any,
        closed: number,
      ) => {
        if (!processedData.repNames[repId]) {
          return null
        }
        return {
          rep: processedData.repNames[repId],
          pipeline,
          closed,
          quota: quotasByRepAndTeam[repId]?.[team.id]?.amount,
        }
      }

      const createSegmentedDataset = (
        quarter: typeof THIS_QUARTER | typeof NEXT_QUARTER,
        segment: string,
        segmentIndex: number,
      ) => ({
        label: `${
          quarter === THIS_QUARTER ? 'This' : 'Next'
        } Quarter • ${segment}`,
        data: flatMapTeamMembers(teams, (team: any, member: any, _1, _2) => {
          const repId = member.salesforce_user_id
          const pipeline =
            quarter === THIS_QUARTER
              ? processedData.pipelineThisQuarterBySegment[repId]?.[segment] ||
                0
              : processedData.pipelineNextQuarterBySegment[repId]?.[segment] ||
                0
          return createRepData(
            repId,
            pipeline,
            team,
            closedWonThisQuarter(repId),
          )
        }),
        backgroundColor: makeHexLighter(
          getColorByIndex(quarter === THIS_QUARTER ? 0 : 1, 2),
          segmentIndex * 20,
        ),
      })

      const createNonSegmentedDataset = (
        quarter: typeof THIS_QUARTER | typeof NEXT_QUARTER,
      ) => ({
        label: `${quarter === THIS_QUARTER ? 'This' : 'Next'} Quarter`,
        data: flatMapTeamMembers(
          teams,
          (team: any, member: any, team_index: number, _3) => {
            const repId = member.salesforce_user_id
            const pipeline =
              quarter === THIS_QUARTER
                ? pipelineThisQuarter(repId)
                : pipelineNextQuarter(repId)
            return createRepData(
              repId,
              pipeline,
              team,
              closedWonThisQuarter(repId),
            )
          },
        ),
        backgroundColor: flatMapTeamMembers(
          teams,
          (_1, _2, team_index: number, _3) =>
            quarter === THIS_QUARTER
              ? getColorByIndex(team_index, teams.length)
              : makeHexLighter(getColorByIndex(team_index, teams.length), 70),
        ),
      })

      return showSegments
        ? [
            ...orderedSegments.map((segment, index) =>
              createSegmentedDataset(THIS_QUARTER, segment, index),
            ),
            ...orderedSegments.map((segment, index) =>
              createSegmentedDataset(NEXT_QUARTER, segment, index),
            ),
          ]
        : [
            createNonSegmentedDataset(THIS_QUARTER),
            createNonSegmentedDataset(NEXT_QUARTER),
          ]
    })(),
  }

  const chartData = groupByTeams ? teamGroupedChartData : justRepsChartData

  const chartOptions = {
    parsing: {
      xAxisKey: 'rep',
      yAxisKey: 'pipeline',
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
          font: {
            size: 12,
          },
        },
      },
      datalabels: { display: false },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw.pipeline || 0
            const remainingQuota =
              context.raw.quota && context.raw.closed
                ? context.raw.quota - context.raw.closed
                : undefined
            const labels = [`${context.dataset.label}: ${formatAmount(value)}`]
            if (remainingQuota) {
              labels.push(
                remainingQuota > 0
                  ? `Remaining Quota: ${formatAmount(remainingQuota)}`
                  : 'Met quota 🚀',
              )
            }
            return labels
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: { display: false },
        ticks: {
          callback: function (this: any, tickValue: number | string) {
            return formatAmount(Number(tickValue)).toString()
          },
        },
      },
    },
  }

  function renderQuotaCoverage(
    repId: string,
    quarter: typeof THIS_QUARTER | typeof NEXT_QUARTER,
    teamId?: string,
  ) {
    const pipeline =
      quarter === THIS_QUARTER
        ? pipelineThisQuarter(repId)
        : pipelineNextQuarter(repId)
    const closedWon = quarter === THIS_QUARTER ? closedWonThisQuarter(repId) : 0

    const quota = teamId
      ? quotasByRepAndTeam[repId]?.[teamId]?.amount
      : quotasByRep[repId]?.amount

    if (quota) {
      const quotaRemaining = quota - closedWon
      if (quotaRemaining <= 0) {
        return '🚀'
      }

      const quotaCoverage = pipeline / quotaRemaining
      return `${quotaCoverage.toFixed(1)}x`
    }
    return '-'
  }

  return (
    <>
      <HeaderContainer>
        <MetricHeader
          icon={icon}
          backgroundColor={backgroundColor}
          name={name}
          description={description}
        />
        <SelectorsContainer>
          <FieldSelector
            type='amount'
            selectedField={selectedAmountField}
            availableFields={amountFields}
            onFieldChange={onAmountFieldChange}
          />
          {segmentationFieldConfigurable && (
            <FieldSelector
              type='segmentation'
              selectedField={segmentationField}
              availableFields={segmentableFields}
              onFieldChange={onSegmentationFieldChange}
            />
          )}
        </SelectorsContainer>
      </HeaderContainer>
      <div style={{ height: '50vh' }}>
        <Bar
          key={showSegments ? 'segmented' : 'unsegmented'} // This key ensures the entire chart is re-rendered when the segments change
          data={chartData}
          options={chartOptions}
          plugins={[quotaIndicatorPlugin]}
        />
      </div>
      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table
          style={{ width: 'auto', minWidth: '70%' }}
          className={styles.fixedFirstColumn}
          variant='simple'
          size='sm'
        >
          <Thead>
            <Tr>
              <Th colSpan={3}></Th>
              <Th colSpan={2} textAlign='center'>
                This Quarter
              </Th>
              <Th colSpan={2} textAlign='center'>
                Next Quarter
              </Th>
            </Tr>
            <Tr>
              <Th>Rep</Th>
              <Th textAlign='center'>Quota</Th>
              <Th textAlign='center'>Closed</Th>
              <Th textAlign='right'>Pipeline</Th>
              <Th textAlign='center'>Coverage</Th>
              <Th textAlign='right'>Pipeline</Th>
              <Th textAlign='center'>Coverage</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupByTeams
              ? teams.map(
                  (team: any, team_index: number) =>
                    team.team_members.length > 0 && (
                      <>
                        <Tr key={team.id}>
                          <Th colSpan={3} paddingTop='16px'>
                            {team.name}
                          </Th>
                        </Tr>
                        {team.team_members.map((member: any) => {
                          const repId = member.salesforce_user_id
                          const repName = processedData.repNames[repId]
                          return (
                            repName && (
                              <Tr key={repId}>
                                <Td>
                                  <CohortCell
                                    onClick={() => setSelectedRep(repId)}
                                    color={
                                      showSegments
                                        ? undefined
                                        : getColorByIndex(
                                            team_index,
                                            teams.length,
                                          )
                                    }
                                  >
                                    {repName}
                                  </CohortCell>
                                </Td>
                                <Td textAlign='right'>
                                  {quotasByRepAndTeam[repId]?.[team.id]?.amount
                                    ? formatAmount(
                                        quotasByRepAndTeam[repId]?.[team.id]
                                          ?.amount,
                                      )
                                    : '-'}
                                </Td>
                                <Td textAlign='right'>
                                  {formatAmount(closedWonThisQuarter(repId))}
                                </Td>
                                <Td textAlign='right'>
                                  {formatAmount(pipelineThisQuarter(repId))}
                                </Td>
                                <Td textAlign='center'>
                                  {renderQuotaCoverage(
                                    repId,
                                    THIS_QUARTER,
                                    team.id,
                                  )}
                                </Td>
                                <Td textAlign='right'>
                                  {formatAmount(pipelineNextQuarter(repId))}
                                </Td>
                                <Td textAlign='center'>
                                  {renderQuotaCoverage(
                                    repId,
                                    NEXT_QUARTER,
                                    team.id,
                                  )}
                                </Td>
                              </Tr>
                            )
                          )
                        })}
                      </>
                    ),
                )
              : processedData.reps.map((repId, index) => (
                  <Tr key={repId}>
                    <Td>
                      <CohortCell
                        onClick={() => setSelectedRep(repId)}
                        color={
                          showSegments
                            ? undefined
                            : getColorByIndex(index, processedData.reps.length)
                        }
                      >
                        {processedData.repNames[repId]}
                      </CohortCell>
                    </Td>
                    <Td textAlign='right'>
                      {quotasByRep[repId]?.amount
                        ? formatAmount(quotasByRep[repId]?.amount)
                        : '-'}
                    </Td>
                    <Td textAlign='right'>
                      {formatAmount(closedWonThisQuarter(repId))}
                    </Td>
                    <Td textAlign='right'>
                      {formatAmount(pipelineThisQuarter(repId))}
                    </Td>
                    <Td textAlign='center'>
                      {renderQuotaCoverage(repId, THIS_QUARTER)}
                    </Td>
                    <Td textAlign='right'>
                      {formatAmount(pipelineNextQuarter(repId))}
                    </Td>
                    <Td textAlign='center'>
                      {renderQuotaCoverage(repId, NEXT_QUARTER)}
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </div>

      <CohortDrillDown
        selectedCohort={selectedRep}
        opportunitiesByCohort={processedData.repOpportunities}
        cohortField={cohortField!.name}
        salesforceInstanceUrl={salesforceInstanceUrl}
        renderCohortName={cohort => processedData.repNames[cohort]}
      />
    </>
  )
}

export default OpenPipelineByRep
