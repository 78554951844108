import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { Chart as ChartJS, LineElement, PointElement } from 'chart.js'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { getColorByIndex } from '../../../lib/colors'
import { CohortCell } from '../CohortCell'
import CohortDrillDown, { useCohortDrillDown } from '../CohortDrillDown'
import MetricHeader from '../MetricHeader'
import styles from '../pipelineAnalytics.module.css'
import {
  BaseMetricProps,
  BucketingProps,
  Opportunity,
  StageMetadata,
} from '../Types'
import {
  categorizeDealStages,
  cohortNameFromDate,
  HeaderContainer,
  renderCohortName,
} from '../Utilities'
import {
  addDaysToStageToOpportunities,
  calculateStageChangeDates,
  groupDataForTable,
  mapTableDataToAverageDaysToStage,
} from './DaysToReachXStageLogic'

type Props = BaseMetricProps & BucketingProps

function DaysToReachXStage({
  data,
  fiscalYearStartMonth,
  bucketingScheme,
  salesforceInstanceUrl,
  name,
  icon,
  backgroundColor,
  description,
  accountId,
  cohortField,
}: Props) {
  const orderedDealStages: string[] = useMemo(
    () => data?.deal_stages.map((stage: StageMetadata) => stage.label) ?? [],
    [data?.deal_stages],
  )

  const categorizedDealStages = useMemo(
    () => categorizeDealStages(data?.deal_stages),
    [data?.deal_stages],
  )

  const {
    cohortsFound,
    tableData,
    cohortOpportunities,
    cohortOpenOpportunities,
  } = useMemo(() => {
    if (!data || !data.opportunities || !data.opportunity_history) {
      return {
        cohortsFound: [],
        tableData: {},
        cohortOpportunities: {},
        cohortOpenOpportunities: {},
      }
    }

    function mappedStageName(stageName: string) {
      if (stageName in data.old_deal_stage_mapping) {
        return data.old_deal_stage_mapping[stageName]
      }
      return stageName
    }

    const changesPerOpportunity = calculateStageChangeDates(
      data.opportunity_history,
      mappedStageName,
    )

    const categorizedDealStages = categorizeDealStages(data.deal_stages)

    const opportunitiesWithDaysToStage = addDaysToStageToOpportunities(
      data.opportunities || [],
      changesPerOpportunity,
      orderedDealStages,
      categorizedDealStages,
    )

    // Collect opportunities by cohort while processing the data
    const cohortOpportunities: Record<string, Opportunity[]> = {}
    const cohortOpenOpportunities: Record<string, number> = {}
    opportunitiesWithDaysToStage.forEach((opp: Opportunity) => {
      const cohort = cohortNameFromDate(
        opp[cohortField!.name] as string,
        bucketingScheme,
        fiscalYearStartMonth,
        accountId,
      )
      if (!cohortOpportunities[cohort]) {
        cohortOpportunities[cohort] = []
        cohortOpenOpportunities[cohort] = 0
      }
      cohortOpportunities[cohort].push(opp)
      if (!opp.IsClosed) {
        cohortOpenOpportunities[cohort]++
      }
    })

    const { tableData, cohortsFound } = groupDataForTable(
      opportunitiesWithDaysToStage,
      orderedDealStages,
      bucketingScheme,
      fiscalYearStartMonth,
      accountId,
    )

    const averageDaysToStage = mapTableDataToAverageDaysToStage(tableData)

    return {
      cohortsFound: Array.from(cohortsFound).sort(),
      tableData: averageDaysToStage,
      cohortOpportunities,
      cohortOpenOpportunities,
    }
  }, [
    data,
    orderedDealStages,
    bucketingScheme,
    fiscalYearStartMonth,
    accountId,
    cohortField,
  ])

  const { selectedCohort, setSelectedCohort } = useCohortDrillDown(
    cohortField!,
    bucketingScheme,
  )

  ChartJS.register(PointElement, LineElement)

  const colors = Array.from(cohortsFound).reduce(
    (acc: Record<string, string>, cohort, index) => {
      acc[cohort] = getColorByIndex(index, cohortsFound.length)
      return acc
    },
    {},
  )

  const verticalHoverLine = {
    id: 'verticalHoverLine',
    beforeDatasetsDraw(chart: any, args: any, plugins: any) {
      const {
        ctx,
        chartArea: { top, bottom, height },
      } = chart

      ctx.save()

      chart.getDatasetMeta(0).data.forEach((point: any, index: any) => {
        if (point.active) {
          ctx.beginPath()
          ctx.strokeStyle = 'grey'
          ctx.moveTo(point.x, top)
          ctx.lineTo(point.x, bottom)
          ctx.stroke()
        }
      })
      ctx.restore()
    },
  }

  const lineChartData = {
    labels: orderedDealStages.filter(
      stage => !categorizedDealStages.lostStageLabels.has(stage),
    ),
    datasets: Array.from(cohortsFound).map(cohort => ({
      label: renderCohortName(cohort, bucketingScheme),
      backgroundColor: colors[cohort],
      borderColor: colors[cohort],
      data: orderedDealStages
        .filter(stage => !categorizedDealStages.lostStageLabels.has(stage))
        .map(dealStage => {
          const value = tableData?.[dealStage]?.[cohort]
          return value !== null && value !== undefined && !isNaN(value)
            ? value
            : null
        }),
    })),
  }
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Avg. Days to Reach',
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  }

  return (
    <>
      <HeaderContainer>
        <MetricHeader
          icon={icon}
          backgroundColor={backgroundColor}
          name={name}
          description={description}
        />
      </HeaderContainer>
      <div style={{ height: '50vh' }}>
        <Line
          data={lineChartData}
          options={lineChartOptions}
          plugins={[verticalHoverLine]}
        />
      </div>

      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>{cohortField!.label}</Th>
              <Th># Open</Th>
              {orderedDealStages.map(dealStage => (
                <Th
                  sx={{
                    textTransform: 'none',
                    letterSpacing: 'none',
                    color: '#71717a',
                    fontWeight: '500',
                  }}
                  key={dealStage}
                >
                  {dealStage}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Array.from(cohortsFound).map(cohort => {
              const openOpportunitiesPercent =
                (cohortOpenOpportunities[cohort] * 100) /
                cohortOpportunities[cohort].length
              return (
                <Tr key={cohort}>
                  <Td>
                    <CohortCell
                      onClick={() => setSelectedCohort(cohort)}
                      color={colors[cohort]}
                    >
                      {renderCohortName(cohort, bucketingScheme)}
                    </CohortCell>
                  </Td>
                  <Td style={{ whiteSpace: 'nowrap' }}>
                    {cohortOpenOpportunities[cohort]}{' '}
                    <span style={{ color: '#71717a', fontSize: '0.9em' }}>
                      ({openOpportunitiesPercent.toFixed(1)}%)
                    </span>
                  </Td>
                  {orderedDealStages.map(dealStage => {
                    const value = tableData?.[dealStage]?.[cohort]
                    return (
                      <Td key={dealStage}>
                        {value !== null && value !== undefined && !isNaN(value)
                          ? value.toFixed(1)
                          : '-'}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </div>

      <CohortDrillDown
        selectedCohort={selectedCohort}
        opportunitiesByCohort={cohortOpportunities}
        cohortField={cohortField!.name}
        salesforceInstanceUrl={salesforceInstanceUrl}
        renderCohortName={cohort => renderCohortName(cohort, bucketingScheme)}
      />
    </>
  )
}
export default DaysToReachXStage
