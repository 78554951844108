export const iconColors = [
  '#8DA4EF',
  '#E093B2',
  '#5EB1EF',
  '#65BA74',
  '#CEA37E',
  '#9B9EF0',
  '#E592A3',
  '#3DB9CF',
  '#5BB98B',
  '#EC9455',
  '#AA99EC',
  '#EB8E90',
  '#60B3D7',
  '#56BA9F',
  '#E2A336',
]

export const highlightIconColors = [
  '#ffdc00',
  '#7ce2fe',
  '#ec9455',
  '#957468',
  '#12a594',
  '#cf91d8',
  '#3e63dd',
  '#b0e64c',
  '#e592a3',
  '#30a46c',
  '#6e56cf',
]

export const highlightColors = [
  '#fefce9',
  '#e1f6fd',
  '#ffefd6',
  '#f6edea',
  '#e0f8f3',
  '#fbebfb',
  '#edf2fe',
  '#eef6d6',
  '#fff7f8',
  '#e6f6eb',
  '#faf8ff',
]
export const lighterIconColors = [
  '#f3f7fe',
  '#fef0f5',
  '#eef7fe',
  '#edf9f0',
  '#fbebe4',
  '#f0f1fd',
  '#fddee4',
  '#def6f8',
  '#ddf6e9',
  '#fde7d8',
  '#f3f0fe',
  '#fddbdd',
  '#e0f6fb',
  '#dbf8ec',
  '#fbefca',
]

export const pillColors = [
  { color: '#3A5BC7', background: '#E1E9FF' },
  { color: '#CA244D', background: '#FFDCE1' },
  { color: '#00749E', background: '#D1F0FA' },
  { color: '#0D74CE', background: '#D5EFFF' },
  { color: '#953EA3', background: '#F7DEF8' },
  { color: '#AB6400', background: '#FFEE9C' },
  { color: '#8145B5', background: '#F2E2FC' },
  { color: '#027864', background: '#C8F4E9' },
  { color: '#208368', background: '#D6F1E3' },
  { color: '#CC4E00', background: '#FFDFB5' },
  { color: '#C2298A', background: '#FBDCEF' },
  { color: '#5C7C2F', background: '#E2F0BD' },
]

export const getColorByIndex = (index: number = 0, size: number = 8) => {
  const colors = getPaletteGeneratorColors(size)
  return colors[index % colors.length]
}

export function makeHexLighter(hex: string, amount: number) {
  return (
    '#' +
    (hex.slice(1).match(/.{2}/g) || [])
      .map(h =>
        Math.min(parseInt(`0x${h}`) + amount, 255)
          .toString(16)
          .padStart(2, '0'),
      )
      .join('')
  )
}

// Colors from the palette generator at https://www.learnui.design/tools/data-color-picker.html#palette
const paletteGeneratorColors: Record<number, string[]> = {
  1: ['#003f5c'],
  2: ['#003f5c', '#ffa600'],
  3: ['#003f5c', '#bc5090', '#ffa600'],
  4: ['#003f5c', '#7a5195', '#ef5675', '#ffa600'],
  5: ['#003f5c', '#58508d', '#bc5090', '#ff6361', '#ffa600'],
  6: ['#003f5c', '#444e86', '#955196', '#dd5182', '#ff6e54', '#ffa600'],
  7: [
    '#003f5c',
    '#374c80',
    '#7a5195',
    '#bc5090',
    '#ef5675',
    '#ff764a',
    '#ffa600',
  ],
  8: [
    '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600',
  ],
}

export function getPaletteGeneratorColors(size: number = 8) {
  const numberOfColors = Math.ceil(size / Math.ceil(size / 8))
  return paletteGeneratorColors[numberOfColors]
}
