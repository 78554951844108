import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { getColorByIndex } from '../../../lib/colors'
import { formatAmount } from '../../../lib/dealUtils'
import { CohortCell } from '../CohortCell'
import CohortDrillDown, { useCohortDrillDown } from '../CohortDrillDown'
import FieldSelector from '../FieldSelector'
import MetricHeader from '../MetricHeader'
import styles from '../pipelineAnalytics.module.css'
import {
  AmountFieldProps,
  BaseMetricProps,
  BucketingProps,
  Opportunity,
  SegmentationProps,
} from '../Types'
import {
  HeaderContainer,
  SelectorsContainer,
  categorizeDealStages,
  getNumericValue,
  getStringValue,
} from '../Utilities'

// Explicitly define the props type for this metric
type Props = BaseMetricProps &
  AmountFieldProps &
  SegmentationProps &
  BucketingProps

function NewPipelineByRep({
  data,
  fiscalYearStartMonth,
  bucketingScheme,
  salesforceInstanceUrl,
  name,
  icon,
  backgroundColor,
  description,
  accountId,
  cohortField,
  showSegments,
  segmentationField,
  segmentationFieldConfigurable,
  segmentableFields,
  onSegmentationFieldChange,
  selectedAmountField,
  amountFields,
  onAmountFieldChange,
}: Props) {
  const orderedStageLabels = useMemo(() => {
    if (data.deal_stages) {
      const categorizedStages = categorizeDealStages(data.deal_stages)
      return [
        ...Array.from(categorizedStages.orderedOpenStageLabels),
        ...Array.from(categorizedStages.wonStageLabels),
        ...Array.from(categorizedStages.lostStageLabels),
      ]
    }
    return null
  }, [data.deal_stages])

  const teams = (data?.teams ?? []).filter(
    (team: any) => team.team_members?.length > 0,
  )
  const groupByTeams = teams.length > 1

  const processedData = useMemo(() => {
    if (!data || !data.opportunities) {
      return {
        reps: [],
        repNames: {},
        channels: new Set<string>(),
        totalsByRep: {},
        totalsByRepAndChannel: {},
        repOpportunities: {},
      }
    }

    const result = data.opportunities.reduce(
      (
        acc: {
          reps: string[]
          repNames: Record<string, string>
          channels: Set<string>
          totalsByRep: Record<string, number>
          totalsByRepAndChannel: Record<string, Record<string, number>>
          repOpportunities: Record<string, Opportunity[]>
        },
        opp: Opportunity,
      ) => {
        const amount = getNumericValue(opp, selectedAmountField) ?? 0
        const channel = getStringValue(opp, segmentationField)

        if (!acc.repNames[opp.OwnerId]) {
          acc.reps.push(opp.OwnerId)
          acc.repNames[opp.OwnerId] = opp.Owner.Name
        }

        if (!acc.repOpportunities[opp.OwnerId]) {
          acc.repOpportunities[opp.OwnerId] = []
        }
        acc.repOpportunities[opp.OwnerId].push(opp)

        acc.channels.add(channel)
        acc.totalsByRep[opp.OwnerId] =
          (acc.totalsByRep[opp.OwnerId] || 0) + amount

        if (!acc.totalsByRepAndChannel[opp.OwnerId]) {
          acc.totalsByRepAndChannel[opp.OwnerId] = {}
        }
        acc.totalsByRepAndChannel[opp.OwnerId][channel] =
          (acc.totalsByRepAndChannel[opp.OwnerId][channel] || 0) + amount

        return acc
      },
      {
        reps: [],
        repNames: {},
        channels: new Set<string>(),
        totalsByRep: {},
        totalsByRepAndChannel: {},
        repOpportunities: {},
      },
    )
    result.reps.sort(
      (a: string, b: string) => result.totalsByRep[b] - result.totalsByRep[a],
    )

    return result
  }, [data, segmentationField, selectedAmountField])

  const { selectedCohort: selectedRep, setSelectedCohort: setSelectedRep } =
    useCohortDrillDown(cohortField!, bucketingScheme)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  )

  const getTeamDatasets = () => {
    return showSegments
      ? (segmentationField === 'StageName' && orderedStageLabels
          ? orderedStageLabels
          : Array.from(processedData.channels as Set<string>)
        ).map((channel: string, index: number) => ({
          label: channel,
          data:
            teams
              ?.map((team: any) =>
                team.team_members.map((member: any) => {
                  const repId = member.salesforce_user_id
                  return (
                    processedData.totalsByRepAndChannel[repId]?.[channel] || 0
                  )
                }),
              )
              .flat() ?? [],
          backgroundColor: getColorByIndex(index, processedData.channels.size),
        }))
      : teams.map((team: any, index: number) => ({
          label: team.name,
          data: teams
            .map((t: any) =>
              t.id === team.id
                ? t.team_members.map(
                    (m: any) =>
                      processedData.totalsByRep[m.salesforce_user_id] || 0,
                  )
                : t.team_members.map(() => null),
            )
            .flat(),
          backgroundColor: getColorByIndex(index, teams.length),
        })) ?? []
  }

  const getRepDatasets = () => {
    return showSegments
      ? (segmentationField === 'StageName' && orderedStageLabels
          ? orderedStageLabels
          : Array.from(processedData.channels as Set<string>)
        ).map((channel: string, index: number) => ({
          label: channel,
          data: processedData.reps.map(
            (repId: string) =>
              processedData.totalsByRepAndChannel[repId]?.[channel] || 0,
          ),
          backgroundColor: getColorByIndex(index, processedData.channels.size),
        }))
      : [
          {
            label: 'Pipeline Amount',
            data: processedData.reps.map(
              (repId: string) => processedData.totalsByRep[repId] || 0,
            ),
            backgroundColor: processedData.reps.map(
              (_: string, index: number) =>
                getColorByIndex(index, processedData.reps.length),
            ),
          },
        ]
  }

  const getChartData = (groupByTeams: boolean) => {
    if (groupByTeams) {
      return {
        labels:
          teams
            .map((team: any) =>
              team.team_members.map(
                (member: any) =>
                  processedData.repNames[member.salesforce_user_id],
              ),
            )
            .flat() ?? [],
        datasets: getTeamDatasets(),
      }
    } else {
      return {
        labels: processedData.reps.map(
          (repId: string) => processedData.repNames[repId],
        ),
        datasets: getRepDatasets(),
      }
    }
  }

  const chartData = getChartData(groupByTeams)

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: groupByTeams || showSegments,
        position: 'bottom' as const,
      },
      datalabels: { display: false },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw || 0
            return `${context.dataset.label}: ${formatAmount(value).toString()}`
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        stacked: true,
        categoryPercentage: 0.5,
        barPercentage: 1.5,
      },
      y: {
        beginAtZero: true,
        grid: { display: false },
        stacked: showSegments,
        ticks: {
          callback: function (this: any, tickValue: number | string) {
            return formatAmount(Number(tickValue)).toString()
          },
        },
      },
    },
  }

  return (
    <>
      <HeaderContainer>
        <MetricHeader
          icon={icon}
          backgroundColor={backgroundColor}
          name={name}
          description={description}
        />
        <SelectorsContainer>
          <FieldSelector
            type='amount'
            selectedField={selectedAmountField}
            availableFields={amountFields}
            onFieldChange={onAmountFieldChange}
          />
          {segmentationFieldConfigurable && (
            <FieldSelector
              type='segmentation'
              selectedField={segmentationField}
              availableFields={segmentableFields}
              onFieldChange={onSegmentationFieldChange}
            />
          )}
        </SelectorsContainer>
      </HeaderContainer>
      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table
          style={{ width: 'auto', minWidth: '50%' }}
          className={styles.fixedFirstColumn}
          variant='simple'
          size='sm'
        >
          <Thead>
            <Tr>
              <Th>Rep</Th>
              <Th textAlign='right'>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupByTeams
              ? teams?.map((team: any, team_index: number) => (
                  <React.Fragment key={team.id}>
                    <Tr>
                      <Th colSpan={2} paddingTop='16px'>
                        {team.name}
                      </Th>
                    </Tr>
                    {team.team_members.map(
                      (member: any) =>
                        member.salesforce_user_id in processedData.repNames && (
                          <Tr key={member.salesforce_user_id}>
                            <Td>
                              <CohortCell
                                onClick={() =>
                                  setSelectedRep(member.salesforce_user_id)
                                }
                                color={getColorByIndex(
                                  team_index,
                                  teams.length,
                                )}
                              >
                                {
                                  processedData.repNames[
                                    member.salesforce_user_id
                                  ]
                                }
                              </CohortCell>
                            </Td>
                            <Td textAlign='right'>
                              {formatAmount(
                                processedData.totalsByRep[
                                  member.salesforce_user_id
                                ],
                              )}
                            </Td>
                          </Tr>
                        ),
                    )}
                  </React.Fragment>
                ))
              : processedData.reps.map((repId: string, index: number) => (
                  <Tr key={repId}>
                    <Td>
                      <CohortCell
                        onClick={() => setSelectedRep(repId)}
                        color={
                          showSegments
                            ? undefined
                            : getColorByIndex(index, processedData.reps.length)
                        }
                      >
                        {processedData.repNames[repId]}
                      </CohortCell>
                    </Td>
                    <Td textAlign='right'>
                      {formatAmount(processedData.totalsByRep[repId])}
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </div>

      <CohortDrillDown
        selectedCohort={selectedRep}
        opportunitiesByCohort={processedData.repOpportunities}
        cohortField={cohortField!.name}
        salesforceInstanceUrl={salesforceInstanceUrl}
        renderCohortName={cohort => processedData.repNames[cohort]}
      />
    </>
  )
}

export default NewPipelineByRep
