import { Flex, Link } from '@chakra-ui/react'
import { tw } from '@minupalaniappan/brise'
import React, { useContext, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import styled from 'styled-components'
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import Wordmark from './Wordmark'
import { get } from 'lodash'
import { StateStore } from '../Store'
import Api from '../../lib/api'
import { MdOutlineManageAccounts } from 'react-icons/md'

type MagikLogoProps = {
  className?: string
  collapsed?: boolean
  onClick?: () => void
}

const LogoutButton = styled.div`
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  padding: 8px;
  padding-left: 6px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f5f6f8;
    cursor: pointer;
  }
`

const MagikLogo = tw((props: MagikLogoProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { push, reload } = useRouter()

  const { dispatch, state } = useContext(StateStore)
  const user = get(state, 'user')

  return props.collapsed ? (
    <div />
  ) : (
    <Popover
      isOpen={isPopoverOpen}
      positions={['right']}
      onClickOutside={e => {
        e.preventDefault()

        setIsPopoverOpen(false)
      }}
      containerClassName='z-10'
      content={
        <div
          style={{
            marginLeft: '8px',
            borderRadius: '8px',
            width: 'auto',
            minWidth: '200px',
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 12px 0px',
            border: '1px solid rgb(243, 243, 243)',
            padding: '3px',
          }}
        >
          <div
            style={{
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <p style={{ fontSize: '14px' }}>{user?.name}</p>
            <p style={{ fontSize: '12px', color: 'gray' }}>{user?.email}</p>
            <p style={{ fontSize: '12px', color: 'gray' }}>
              <Link href={user?.salesforce_instance_url}>
                {user?.salesforce_instance_url}
              </Link>
            </p>
          </div>
          {process.env.NEXT_PUBLIC_APP_ENV === 'staging' && (
            <LogoutButton
              onClick={async () => {
                await Api.switchView({ isManager: user?.is_manager })
                reload()
              }}
            >
              <ArrowsRightLeftIcon height={20} width={20} />
              <span style={{ marginLeft: '10px' }}>{`${
                user?.is_manager ? 'Rep' : 'Manager'
              } View`}</span>
            </LogoutButton>
          )}
          <LogoutButton
            onClick={() => {
              push('/signout')
            }}
          >
            <ArrowLeftOnRectangleIcon height={20} width={20} />
            <span style={{ marginLeft: '10px' }}>Logout</span>
          </LogoutButton>
        </div>
      }
    >
      <Flex
        {...{ onClick: props.onClick, className: props.className }}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        direction={'row'}
        alignItems={'center'}
        gap={2}
      >
        <div style={{ marginLeft: '-5px' }}>
          <Wordmark scale={0.31} />
        </div>
      </Flex>
    </Popover>
  )
})`
  cursor-pointer
`

export default MagikLogo
