import {
  BucketType,
  CategorizedStageLabels,
  Opportunity,
  OpportunityHistory,
} from '../Types'
import { cohortNameFromDate, daysBetweenDates } from '../Utilities'

type OpportunityWithDaysToStage = Opportunity & {
  DaysToStage: Record<string, number | null>
}

export function groupHistoryByOpportunity(history: OpportunityHistory[]) {
  history.sort(
    (a, b) =>
      new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime(),
  )

  const historyPerOpportunity: Record<string, OpportunityHistory[]> = {}

  history.forEach((history: OpportunityHistory) => {
    const opportunityId = history.OpportunityId
    if (!historyPerOpportunity[opportunityId]) {
      historyPerOpportunity[opportunityId] = []
    }
    historyPerOpportunity[opportunityId].push(history)
  })
  return historyPerOpportunity
}

export function calculateStageChangeDates(
  history: OpportunityHistory[],
  mappedStageName: (stageName: string) => string,
) {
  const changesPerOpportunity: Record<string, Record<string, string>> = {}
  const historyPerOpportunity = groupHistoryByOpportunity(history)

  // for each opportunity, iterate through sorted history and find changes in stage
  Object.keys(historyPerOpportunity).forEach(opportunityId => {
    const history = historyPerOpportunity[opportunityId]
    let previousStage: string | null = null
    history.forEach((history: OpportunityHistory) => {
      const stage = mappedStageName(history.StageName)
      const date = history.CreatedDate
      if (stage !== previousStage) {
        if (!changesPerOpportunity[opportunityId]) {
          changesPerOpportunity[opportunityId] = {}
        }
        changesPerOpportunity[opportunityId][stage] = date
        previousStage = stage
      }
    })
  })

  return changesPerOpportunity
}

/**
 * Calculates how many days it took an opportunity to reach each stage in its lifecycle.
 *
 * For stages that were actually reached:
 * - Always shows the actual number of days it took to reach that stage
 * - This applies to all opportunity types (Won, Open, or Lost)
 *
 * For stages that were skipped:
 * 1. Won Opportunities:
 *    - Inherit days from the next stage that was actually reached
 *    - If no more open stages were reached, inherit from the Closed Won stage
 *
 * 2. Open Opportunities:
 *    - Inherit days from the next stage that was actually reached
 *    - All stages after the current stage are null
 *
 * 3. Lost Opportunities:
 *    - Only inherit days for skipped stages that are between two reached open stages
 *    - Example: If S1 -> S3 -> S4 -> Closed Lost, then S2 inherits from S3
 *    - All stages after the last reached open stage are null
 *    - The Closed Lost stage shows actual days
 *
 * This ensures accurate tracking of stage progression while appropriately
 * handling skipped stages based on the opportunity's outcome.
 */
export function calculateStageDaysForOpportunity(
  opportunity: any,
  changesPerOpportunity: Record<string, Record<string, string>>,
  orderedDealStages: string[],
  processedStages: CategorizedStageLabels,
) {
  const createdDate = opportunity.CreatedDate
  const stageToDate = changesPerOpportunity[opportunity.Id]
  let stageToDays: Record<string, number | null> = {}
  let previousDate: string | null = null

  // Handle closed stages using combined won and lost stage sets
  const closedStages = [
    ...Array.from(processedStages.wonStageLabels),
    ...Array.from(processedStages.lostStageLabels),
  ]

  // Set null for all closed stages - used by averageDaysToStage() to filter out unreached stages
  closedStages.forEach(closedStage => (stageToDays[closedStage] = null))

  // Then set the actual value for the stage it closed in (if any)
  if (opportunity.IsClosed) {
    const stage = opportunity.StageName
    const date = stageToDate[stage]
    stageToDays[stage] = daysBetweenDates(createdDate, date)
    if (opportunity.IsWon) {
      previousDate = date // Need to save won stage date to be used to handle opps that skipped to Closed Won below
    }
  }

  // Handle open stages in reverse order using preserved order from processedStages
  for (const stage of processedStages.orderedOpenStageLabels
    .slice()
    .reverse()) {
    const date = stageToDate?.[stage]
    if (date) {
      stageToDays[stage] = daysBetweenDates(createdDate, date)
      previousDate = date
    } else if (previousDate) {
      stageToDays[stage] = daysBetweenDates(createdDate, previousDate)
    } else {
      stageToDays[stage] = null
    }
  }

  return stageToDays
}

export function addDaysToStageToOpportunities(
  opportunities: Opportunity[],
  changesPerOpportunity: Record<string, Record<string, string>>,
  orderedDealStages: string[],
  categorizedDealStages: CategorizedStageLabels,
) {
  const opportunitiesWithDaysToStage = opportunities.map(opportunity => {
    const daysToStage = calculateStageDaysForOpportunity(
      opportunity,
      changesPerOpportunity,
      orderedDealStages,
      categorizedDealStages,
    )
    return { ...opportunity, DaysToStage: daysToStage }
  })
  return opportunitiesWithDaysToStage
}

export function groupDataForTable(
  opportunities: OpportunityWithDaysToStage[],
  orderedDealStages: string[],
  bucketingScheme: BucketType,
  fiscalYearStartMonth: number,
  accountId: string,
) {
  const tableData: Record<
    string,
    Record<string, OpportunityWithDaysToStage[]>
  > = {}
  orderedDealStages.forEach(stage => {
    tableData[stage] = {}
  })
  const cohortsFound = new Set<string>()
  opportunities.forEach(opportunity => {
    const cohort = cohortNameFromDate(
      opportunity.CreatedDate,
      bucketingScheme,
      fiscalYearStartMonth,
      accountId,
    )
    cohortsFound.add(cohort)
    orderedDealStages.forEach(stage => {
      if (!tableData[stage][cohort]) {
        tableData[stage][cohort] = []
      }
      tableData[stage][cohort].push(opportunity)
    })
  })
  return { tableData, cohortsFound }
}

export function averageDaysToStage(
  opportunities: OpportunityWithDaysToStage[],
  stage: string,
) {
  const includedOpportunities = opportunities.filter(
    opportunity => opportunity.DaysToStage[stage] !== null,
  )
  const totalDays = includedOpportunities.reduce(
    (sum, opportunity) => sum + (opportunity.DaysToStage[stage] ?? 0),
    0,
  )
  return totalDays / includedOpportunities.length
}

export function mapTableDataToAverageDaysToStage(
  tableData: Record<string, Record<string, OpportunityWithDaysToStage[]>>,
) {
  const result: Record<string, Record<string, number>> = {}

  for (const [stage, innerDict] of Object.entries(tableData)) {
    const innerResult: Record<string, number> = {}

    for (const [cohort, opportunities] of Object.entries(innerDict)) {
      innerResult[cohort] = averageDaysToStage(opportunities, stage)
    }

    result[stage] = innerResult
  }

  return result
}
