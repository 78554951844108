import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { getColorByIndex } from '../../../lib/colors'
import { formatAmount } from '../../../lib/dealUtils'
import { CohortCell } from '../CohortCell'
import CohortDrillDown, { useCohortDrillDown } from '../CohortDrillDown'
import FieldSelector from '../FieldSelector'
import MetricHeader from '../MetricHeader'
import styles from '../pipelineAnalytics.module.css'
import {
  AmountFieldProps,
  BaseMetricProps,
  BucketingProps,
  Opportunity,
  SegmentationProps,
} from '../Types'
import {
  HeaderContainer,
  SelectorsContainer,
  categorizeDealStages,
  cohortNameFromDate,
  getNumericValue,
  renderCohortName,
} from '../Utilities'

// Explicitly define the props type for this metric
type Props = BaseMetricProps &
  BucketingProps &
  AmountFieldProps &
  SegmentationProps

type ProcessedData = {
  cohorts: string[]
  stageAmounts: Record<string, Record<string, number>> // stage -> cohort -> amount
  cohortOpportunities: Record<string, Opportunity[]>
}

function OpenPipelineByStage({
  data,
  fiscalYearStartMonth,
  salesforceInstanceUrl,
  accountId,
  name,
  icon,
  backgroundColor,
  description,
  cohortField,
  bucketingScheme,
  selectedAmountField,
  amountFields,
  onAmountFieldChange,
  segmentationField,
  segmentationFieldConfigurable,
  segmentableFields,
  onSegmentationFieldChange,
}: Props) {
  const openStageLabels = Array.from(
    categorizeDealStages(data.deal_stages).orderedOpenStageLabels,
  ) as string[]

  const processedData = useMemo((): ProcessedData => {
    if (!data || !data.opportunities) {
      return {
        cohorts: [],
        stageAmounts: {},
        cohortOpportunities: {},
      }
    }

    const cohortSet = new Set<string>()
    const stageAmounts: Record<string, Record<string, number>> = {}
    const cohortOpportunities: Record<string, Opportunity[]> = {}

    data.opportunities.forEach((opp: Opportunity) => {
      function mappedStageName(stageName: string) {
        if (stageName in data.old_deal_stage_mapping) {
          return data.old_deal_stage_mapping[stageName]
        }
        return stageName
      }

      if (opp.IsClosed) {
        return
      }

      const cohort = cohortNameFromDate(
        opp[cohortField!.name] as string,
        bucketingScheme,
        fiscalYearStartMonth,
        accountId,
      )
      const stage = mappedStageName(opp.StageName)
      const amount = getNumericValue(opp, selectedAmountField) ?? 0

      cohortSet.add(cohort)

      if (!stageAmounts[stage]) {
        stageAmounts[stage] = {}
      }

      stageAmounts[stage][cohort] = (stageAmounts[stage][cohort] || 0) + amount

      if (!cohortOpportunities[cohort]) {
        cohortOpportunities[cohort] = []
      }
      cohortOpportunities[cohort].push(opp)
    })

    const cohorts = Array.from(cohortSet).sort()

    return {
      cohorts,
      stageAmounts,
      cohortOpportunities,
    }
  }, [
    data,
    bucketingScheme,
    fiscalYearStartMonth,
    accountId,
    selectedAmountField,
    cohortField,
  ])

  const { selectedCohort, setSelectedCohort } = useCohortDrillDown(
    cohortField!,
    bucketingScheme,
  )

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
  )

  const chartData = {
    labels: processedData.cohorts.map(cohort =>
      renderCohortName(cohort, bucketingScheme),
    ),
    datasets: openStageLabels.map((stage: string, index: number) => ({
      label: stage,
      data: processedData.cohorts.map(
        cohort => processedData.stageAmounts[stage]?.[cohort] || 0,
      ),
      backgroundColor: getColorByIndex(index, openStageLabels.length),
    })),
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw || 0
            return `${context.dataset.label}: ${formatAmount(value)}`
          },
        },
      },
      datalabels: {
        display: (context: any) => {
          const highestVisibleStage = Math.max(
            ...context.chart.data.datasets
              .map((_: any, i: number) => {
                return context.chart.getDatasetMeta(i)?.visible ? i : null
              })
              .filter((n: number | null) => n !== null),
          )
          return context.datasetIndex === highestVisibleStage
        },
        formatter: (_: any, context: any) => {
          if (
            context.chart.scales.x.width / context.chart.data.labels.length >
            50
          ) {
            const visibleStages = context.chart.data.datasets
              .filter((_: any, i: number) => {
                return context.chart.getDatasetMeta(i)?.visible
              })
              .map((dataset: any) => {
                return dataset.label
              })

            const cohortIndex = context.dataIndex
            const total = visibleStages.reduce((sum: number, stage: string) => {
              return (
                sum +
                (processedData.stageAmounts[stage]?.[
                  processedData.cohorts[cohortIndex]
                ] || 0)
              )
            }, 0)

            return formatAmount(total)
          }
          return ''
        },
        anchor: 'end' as const,
        align: 'top' as const,
        offset: 5,
        font: {
          size: 14,
          weight: 'bold' as const,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: cohortField!.label,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: (value: any) => formatAmount(value),
        },
      },
    },
  }

  return (
    <>
      <HeaderContainer>
        <MetricHeader
          icon={icon}
          backgroundColor={backgroundColor}
          name={name}
          description={description}
        />
        <SelectorsContainer>
          <FieldSelector
            type='amount'
            selectedField={selectedAmountField}
            availableFields={amountFields}
            onFieldChange={onAmountFieldChange}
          />
        </SelectorsContainer>
      </HeaderContainer>
      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>{cohortField!.label}</Th>
              {openStageLabels.map((stage: string) => (
                <Th key={stage}>{stage}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {(() => {
              const totals: Record<string, number> = {}

              // Render cohort rows while accumulating totals
              const rows = processedData.cohorts.map((cohort, index) => {
                const currentFiscalYear = cohort.split('-')[0]
                const isNewFiscalYear =
                  index === 0 ||
                  currentFiscalYear !==
                    processedData.cohorts[index - 1].split('-')[0]
                const rowClass = isNewFiscalYear
                  ? styles['fiscal-year-boundary']
                  : ''

                return (
                  <Tr key={cohort} className={rowClass}>
                    <Td>
                      <CohortCell onClick={() => setSelectedCohort(cohort)}>
                        {renderCohortName(cohort, bucketingScheme)}
                      </CohortCell>
                    </Td>
                    {openStageLabels.map((stage: string) => {
                      const amount =
                        processedData.stageAmounts[stage]?.[cohort] || 0
                      totals[stage] = (totals[stage] || 0) + amount
                      return (
                        <Td key={stage} textAlign='right'>
                          {formatAmount(amount)}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })

              rows.push(
                <Tr key='cumulative' className={styles['fiscal-year-boundary']}>
                  <Td>
                    <strong>Cumulative</strong>
                  </Td>
                  {openStageLabels.map((stage: string) => (
                    <Td key={stage} textAlign='right'>
                      <strong>{formatAmount(totals[stage])}</strong>
                    </Td>
                  ))}
                </Tr>,
              )

              return rows
            })()}
          </Tbody>
        </Table>
      </div>

      <CohortDrillDown
        selectedCohort={selectedCohort}
        opportunitiesByCohort={processedData.cohortOpportunities}
        cohortField={cohortField!.name}
        salesforceInstanceUrl={salesforceInstanceUrl}
        renderCohortName={cohort => renderCohortName(cohort, bucketingScheme)}
      />
    </>
  )
}

export default OpenPipelineByStage
