import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa6'
import { FixedSizeList } from 'react-window'
import { Field } from './Types'

type FieldSelectorType = 'segmentation' | 'amount'

interface FieldSelectorProps {
  type: FieldSelectorType
  selectedField: string
  availableFields: Array<Field>
  onFieldChange: (fieldName: string) => void
}

const FieldSelector = ({
  type,
  selectedField,
  availableFields,
  onFieldChange,
}: FieldSelectorProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  const ITEM_SIZE = 40
  const MAX_ITEMS_VISIBLE = 5
  const listHeight = Math.min(
    availableFields.length * ITEM_SIZE,
    ITEM_SIZE * MAX_ITEMS_VISIBLE,
  )

  const handleFieldSelect = (field: Field) => {
    onFieldChange(field.name)
    onClose()
  }

  const selectedFieldLabel =
    availableFields.length > 0
      ? availableFields.find(f => f.name === selectedField)?.label
      : undefined

  const getLabels = () => {
    switch (type) {
      case 'amount':
        return {
          button: 'Amounts based on:',
          tooltip: 'Select which amount field to use in calculations',
        }
      case 'segmentation':
      default:
        return {
          button: 'Segment by:',
          tooltip: 'Select a field to segment opportunities by',
        }
    }
  }

  const labels = getLabels()

  const ItemRenderer = ({ index, style }: { index: number; style: any }) => {
    const sortedFields = [...availableFields].sort((a, b) =>
      a.label.localeCompare(b.label),
    )
    const field = sortedFields[index]
    return (
      <Button
        key={field.name}
        variant='ghost'
        width='100%'
        justifyContent='flex-start'
        px={3}
        py={2}
        borderRadius={0}
        fontWeight={field.name === selectedField ? 'semibold' : 'normal'}
        onClick={() => handleFieldSelect(field)}
        style={style}
        _hover={{ bg: 'gray.50' }}
      >
        <Text isTruncated>{field.label}</Text>
      </Button>
    )
  }

  return (
    <Box>
      <Popover
        placement='bottom-start'
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Tooltip label={labels.tooltip} placement='top' openDelay={1000}>
            <Button
              variant='unstyled'
              onClick={onToggle}
              display='flex'
              alignItems='center'
              height='auto'
              _hover={{ color: 'gray.600' }}
              isDisabled={availableFields.length === 0}
            >
              <Text color='gray.600' mr={1} fontSize='sm'>
                {labels.button}
              </Text>
              {selectedFieldLabel ? (
                <Text fontWeight='medium' mr={1}>
                  {selectedFieldLabel}
                </Text>
              ) : (
                <Text fontWeight='medium' mr={1} color='gray.400'>
                  {availableFields.length === 0 ? 'Loading...' : 'Select field'}
                </Text>
              )}
              <Box
                as={FaChevronDown}
                transform={isOpen ? 'rotate(180deg)' : undefined}
                transition='transform 0.2s'
                fontSize='12px'
              />
            </Button>
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent width='200px' shadow='lg'>
          <PopoverBody p={0}>
            <FixedSizeList
              height={listHeight}
              width='100%'
              itemCount={availableFields.length}
              itemSize={ITEM_SIZE}
            >
              {ItemRenderer}
            </FixedSizeList>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default FieldSelector
