import { ColumnDef, Row, Table } from '@tanstack/react-table'
import { formatAmount } from '../../dealUtils'
import {
  parse as parseFormulaString,
  EquationNode,
  EquationParserError,
} from 'equation-parser'
import { executeCsvDownload } from '../../csv'
import { salesforceDateStringToUTCDate } from '../../dateUtils'

export const formatValueByType = (value: string | number, type: string) => {
  switch (type) {
    case 'date':
      const date = salesforceDateStringToUTCDate(value as string)
      if (!isNaN(date.valueOf())) {
        const dateFormatted = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'UTC',
        }).format(date)

        return dateFormatted
      }

      return '-'
    case 'datetime':
      if (!isNaN(Date.parse(value as string))) {
        const date = new Date(value)

        return date.toLocaleString()
      }
    case 'percent':
      return !!value ? `${value}%` : '-'
    case 'number':
    case 'double':
    case 'int':
      return !isNaN(Number(value)) ? Number(value) : ''
    case 'currency':
      return value !== undefined && value !== null
        ? formatAmount(value as number)
        : ''
    default:
      return value
  }
}

export function getInitialDateTimeValue(value: Date) {
  return new Date(value.getTime() - value.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -1)
}

export function getGroupedByRows(
  groupings: {
    groupingsDown: Array<any>
    groupingsAcross: Array<any>
  },
  columns: Array<any>,
) {
  return groupings.groupingsDown.map(group => {
    const foundColumn = columns.find(column => group.name === column.columnName)
    if (foundColumn) {
      return foundColumn.columnName
    }

    return {
      label: group.name,
      value: group.name,
    }
  })
}

// Extract value from report row based on datatype
export const getValue = (value: any, columnType: string) => {
  if (!value) return null

  switch (columnType) {
    case 'currency':
      return value.value?.amount as number
    case 'number':
    case 'double':
    case 'int':
      return value.value as number
    case 'date':
    case 'datetime':
      return value.value ?? ''
    case 'percent':
      return (value.value as number) ?? ''
    default:
      return value.label ?? '-'
  }
}

export const getInputProps = (dataType: string) => {
  switch (dataType) {
    case 'currency':
      return {
        type: 'number',
        min: 0,
        step: 0.01,
      }
    case 'date':
      return {
        type: 'date',
        width: 'fit-content',
      }
    case 'datetime':
      return {
        type: 'datetime-local',
      }
    case 'double':
    case 'number':
    case 'int':
    case 'percent':
      return {
        type: 'number',
      }
    default:
      return {}
  }
}

type FormulaName =
  | 'SUM'
  | 'DIVIDE'
  | 'MULTIPLY'
  | 'AVG'
  | 'SUBTRACT'
  | 'DAYS'
  | 'YEARS'
  | 'MONTHS'

interface FormulaDefinition {
  formulaName: FormulaName
  firstValue: string | number | FormulaDefinition
  secondValue?: string | number | FormulaDefinition
}

const createDefinition = (
  op: FormulaName,
  first: string,
  second?: string,
): FormulaDefinition => ({
  formulaName: op,
  firstValue: parseInner(first),
})

function getFormulaForType(
  type:
    | 'plus'
    | 'minus'
    | 'multiply-dot'
    | 'multiply-implicit'
    | 'divide-inline'
    | 'divide-fraction',
) {
  switch (type) {
    case 'plus':
      return 'SUM'
    case 'minus':
      return 'SUBTRACT'
    case 'divide-fraction':
    case 'divide-inline':
      return 'DIVIDE'
    case 'multiply-dot':
    case 'multiply-implicit':
      return 'MULTIPLY'
    default: {
      return 'SUM'
    }
  }
}

function convertToFormulaDefinition(
  formula: EquationNode,
  replacedValues: { [key: string]: string },
): any {
  if (
    formula.type === 'plus' ||
    formula.type === 'minus' ||
    formula.type === 'multiply-dot' ||
    formula.type === 'multiply-implicit' ||
    formula.type === 'divide-inline' ||
    formula.type === 'divide-fraction'
  ) {
    return {
      formulaName: getFormulaForType(formula.type),
      firstValue: convertToFormulaDefinition(
        formula['a'] as any,
        replacedValues,
      ),
      secondValue: convertToFormulaDefinition(formula['b'], replacedValues),
    }
  } else if (formula.type === 'function') {
    return {
      formulaName: formula.name,
      firstValue: convertToFormulaDefinition(formula.args[0], replacedValues),
      ...(formula.args[1] && {
        secondValue: convertToFormulaDefinition(
          formula.args[1],
          replacedValues,
        ),
      }),
    }
  } else if (formula.type === 'variable') {
    if (replacedValues[formula.name]) {
      return replacedValues[formula.name]
    } else {
      return formula.name
    }
  } else if (formula.type === 'number') {
    return Number(formula.value)
  } else if (formula.type === 'block') {
    return convertToFormulaDefinition(formula.child, replacedValues)
  }
  return {} as FormulaDefinition
}

export function parseFormula(formula: string, options: Array<string>) {
  // Need to replace . in names as that gets interpreted as a function by the library
  // Replace all . with underscores and keep track of them.
  // Once formula is constructed, then replace all the converted names with .

  const splitUpFormula = formula.split(' ')
  const replacedValues: { [key: string]: string } = {}

  const reconstructedString = splitUpFormula.reduce((memo, formulaVal) => {
    if (formulaVal.includes('.')) {
      const replaced = formulaVal.replaceAll('.', '_')
      replacedValues[replaced] = formulaVal

      memo += `${replaced} `
    } else {
      memo += `${formulaVal} `
    }
    return memo
  }, '')

  const parsedFormula = parseFormulaString(reconstructedString)

  let errors: Array<string> = []
  const replacedOptions = options.map(option => option.replaceAll('.', '_'))

  if (parsedFormula.type !== 'parser-error') {
    validateFormula(parsedFormula, replacedOptions, errors)
  } else {
    errors = [
      `${mapParserErrorToMessage(parsedFormula)}${
        parsedFormula.start === parsedFormula.end
          ? ''
          : ` at ${parsedFormula.equation.substring(
              parsedFormula.start,
              parsedFormula.end,
            )}`
      }`,
    ]
  }

  const convertedFormula = convertToFormulaDefinition(
    parsedFormula as EquationNode,
    replacedValues,
  )

  return { errors, convertedFormula }
}

function validateFormula(
  node: EquationNode,
  options: Array<string>,
  errors: string[],
): void {
  if (node.type === 'function') {
    // Check if the function is of date type but either has wrong name or wrong number of arguments
    const isValidDateFunction =
      node.name === 'MONTH' || node.name === 'DAY' || node.name === 'YEAR'
    if (!isValidDateFunction) {
      errors.push(`Unknown function ${node.name}`)
    }
    if (
      isValidDateFunction &&
      node.args.length !== 1 &&
      node.args.length !== 2
    ) {
      errors.push(
        `Invalid number of arguments for date function ${node.name}. Expected 1 or 2, got ${node.args.length}.`,
      )
    }

    node.args.forEach(arg => validateFormula(arg, options, errors))
  } else if (
    node.type === 'plus' ||
    node.type === 'minus' ||
    node.type === 'multiply-dot' ||
    node.type === 'multiply-implicit' ||
    node.type === 'divide-inline' ||
    node.type === 'divide-fraction'
  ) {
    validateFormula(node.a, options, errors)
    validateFormula(node.b, options, errors)
  } else if (node.type === 'block') {
    validateFormula(node.child, options, errors)
  } else if (node.type === 'variable') {
    if (!options.includes(node.name)) {
      errors.push(`Unknown column ${node.name}`)
    }
  }
}

function mapParserErrorToMessage(error: EquationParserError): string {
  switch (error.errorType) {
    case 'numberWhitespace':
      return 'There should not be spaces within numbers.'
    case 'invalidNumber':
      return 'The number format is invalid.'
    case 'adjecentOperator':
      return 'Two operators are adjacent to each other, which is not allowed.'
    case 'invalidChar':
      return `The character '${error.character}' is invalid in this context.`
    case 'multipleExpressions':
      return 'Only one expression is allowed at a time.'
    case 'matrixMixedDimension':
      return `Matrix dimensions are inconsistent. Expected length: ${error.lengthExpected}, received: ${error.lengthReceived}.`
    case 'matrixEmpty':
      return 'Matrices cannot be empty.'
    case 'vectorEmpty':
      return 'Vectors cannot be empty.'
    case 'expectedEnd':
      return 'Unexpected content at the end of the expression.'
    case 'expectedSquareBracket':
      return 'A closing square bracket is expected but missing.'
    case 'expectedCloseParens':
      return 'A closing parenthesis is expected but missing.'
    case 'operatorLast':
      return 'The expression cannot end with an operator.'
    default:
      return 'An unknown parsing error occurred.'
  }
}

function parseDateFunction(
  funcName: FormulaName,
  innerExpression: string,
): FormulaDefinition {
  const parts = innerExpression.split('-').map(part => part.trim())

  return {
    formulaName: funcName,
    firstValue: parts[0],
    ...(parts.length > 1 && {
      secondValue: parts[1],
    }),
  }
}

function parseInner(expression: string): FormulaDefinition | string | number {
  if (!/[\+\-\*\/\(\)]/.test(expression)) {
    return isNaN(parseFloat(expression)) ? expression : parseFloat(expression)
  }

  if (
    expression.startsWith('DAYS(') ||
    expression.startsWith('YEARS(') ||
    expression.startsWith('MONTHS(')
  ) {
    const endIndex = expression.indexOf(')')
    const funcName = expression.substring(
      0,
      expression.indexOf('('),
    ) as FormulaName
    const dateExpression = expression.substring(funcName.length + 1, endIndex)
    return parseDateFunction(funcName, dateExpression)
  }

  let balance = 0,
    operatorPosition = -1,
    operator

  for (let i = 0; i < expression.length; i++) {
    const char = expression[i]

    if (char === '(') balance++
    else if (char === ')') balance--
    else if (balance === 0 && '+-*/'.includes(char)) {
      operatorPosition = i
      operator = char
    }
  }

  if (operatorPosition === -1) {
    return parseInner(expression.substring(1, expression.length - 1))
  }

  const firstPart = expression.substring(0, operatorPosition)
  const secondPart = expression.substring(operatorPosition + 1)

  switch (operator) {
    case '+':
      return createDefinition('SUM', firstPart, secondPart)
    case '-':
      return createDefinition('SUBTRACT', firstPart, secondPart)
    case '*':
      return createDefinition('MULTIPLY', firstPart, secondPart)
    case '/':
      return createDefinition('DIVIDE', firstPart, secondPart)
    default:
      throw new Error('Invalid operator')
  }
}

export const customStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      boxShadow: 'none', // Remove default shadow
      borderRadius: '8px', // Match your border radius
      fontSize: '12px',
      width: '440px',
      fontWeight: '500',
      // backgroundColor: '',
      minHeight: '36px', // or any height that suits your design
      height: '36px', // you can specify a fixed height if you want
      // Decrease top and bottom padding to reduce height
      padding: '0 8px', // adjust the vertical padding as necessary
      cursor: 'pointer',
      border: '1px solid #E0E0E0',
      '&:hover': {
        border: '1px solid #E0E0E0', // Border color on hover
      },
      '&:focus': {
        border: '1px solid #E0E0E0', // Border color on hover
      },
    }
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0 6px',
    fontSize: '13px',
    fontWeight: '500',
    borderRadius: '10px',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: '#333', // Placeholder text color
    fontSize: '12px', // Placeholder font size
    fontWeight: '500',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    alignItems: 'center',
    position: 'relative',
    bottom: '1px',
    background: 'none !important',
    svg: {
      strokeWidth: '1px !important',
    },
    paddingLeft: '0px',
    paddingRight: '0px',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #E0E0E0', // Remove borders
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Optional: add shadow to dropdown
    borderRadius: '10px', // Match your border radius
    fontSize: '12px',
    // width: '340px',
    backgroundColor: 'white',
    overflow: 'hidden',
  }),
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      background: '#F3F3F3',
      border: '1px solid #EFEFEF',
    }
  },
  option: (provided: any, state: any) => ({
    ...provided,
    color: 'black', // Option text color
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'rgb(244, 244, 244)', // Background color on hover
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#333', // Selected value color
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999, // Ensure it's above other content but check for desired layering
    borderRadius: '10px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}

export type Changes = {
  [key: number]: {
    [key: string]: string | number
  }
}

const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`

export function getChangesText(changes: Changes) {
  let changesCount = 0

  Object.values(changes).forEach(value => {
    changesCount += Object.keys(value).length
  })

  return pluralize(changesCount, 'change')
}

export const transformRows = (rows: Array<any>, columns: Array<any>) => {
  return rows.map(row => {
    const transformedRow: { [key: string]: string | number } = {}
    columns.forEach((column: any) => {
      // Use columnName to access the data in each row
      const cellData = row[column.columnName]

      // Assign the value (or label) from cellData to the transformedRow
      transformedRow[column.columnName.split('.').join('_')] = getValue(
        cellData,
        column.dataType,
      )
    })
    return transformedRow
  })
}

export enum AvailableDataType {
  Number = 'number',
  Double = 'double',
  Currency = 'currency',
  Percent = 'percent',
  Int = 'int',
}

export const AGGREGATION_FUNCTION_LABELS_MAP: { [key: string]: string } = {
  sum: 'Sum',
  mean: 'Avg',
  avg: 'Avg',
  average: 'Avg',
  minimum: 'Min',
  maximum: 'Max',
  median: 'Median',
  min: 'Min',
  max: 'Max',
  uniqueCount: 'Unique Count',
  count: 'Count',
  extent: 'Range',
  unique: 'Distinct',
}

export const AGGREGATION_DETAILS_MAP: {
  [key: string]: Array<string>
} = {
  [AvailableDataType.Number]: ['sum', 'mean', 'median', 'uniqueCount'],
  [AvailableDataType.Currency]: ['sum', 'mean', 'median', 'extent'],
  [AvailableDataType.Double]: ['sum', 'mean', 'median', 'uniqueCount'],
  [AvailableDataType.Percent]: ['extent', 'mean', 'median'],
}

// Function to update rows with data changes
export const updateRowsWithChanges = (
  rows: Array<any>,
  columns: Array<any>,
  changes: Changes,
) => {
  Object.entries(changes).forEach(([rowIndex, valuesChanges]) => {
    const rowToUpdate = rows[Number(rowIndex)]
    Object.entries(valuesChanges).forEach(([rowKey, rowValue]) => {
      if (rowToUpdate[rowKey]) {
        const dataType =
          columns.find(col => col.columnName === rowKey)?.dataType ?? ''

        rowToUpdate[rowKey] = getUpdateRowValue(dataType, rowValue)
      }
    })

    rows[Number(rowIndex)] = rowToUpdate
  })

  return rows
}

// Format rowValue
const getUpdateRowValue = (dataType: string, value: string | number) => {
  switch (dataType) {
    case 'number':
    case 'double':
    case 'int':
      return {
        label: value.toString(),
        value: Number(value),
      }
    case 'currency':
      return {
        label: `\$${value.toString()}`,
        value: {
          amount: Number(value),
        },
      }
    case 'date':
      return {
        label: formatValueByType(value, 'date'),
        value,
      }
    default:
      return {
        label: value.toString(),
        value: value.toString(),
      }
  }
}

// Functions to help calculate aggregate values
function findMedian(arr: Array<number>) {
  const middleIndex = Math.floor(arr.length / 2)

  if (arr.length % 2 === 0) {
    return (arr[middleIndex - 1] + arr[middleIndex]) / 2
  } else {
    return arr[middleIndex]
  }
}

export const getAggregatedValue = (
  id: string,
  column: ColumnDef<any, string | number | Array<string>>,
  rows: Row<any>[],
) => {
  if (
    AGGREGATION_DETAILS_MAP[column.meta?.dataType ?? '']?.includes(
      column.meta?.aggregationFunction ?? 'none',
    )
  ) {
    switch (column.meta?.aggregationFunction) {
      case 'sum':
      case 'mean':
        const total = rows.reduce((memo, row) => {
          if (row.getValue(id) && !isNaN(Number(row.getValue(id)))) {
            memo += Number(row.getValue(id)) as number
          }

          return memo
        }, 0)

        if (column.meta.aggregationFunction === 'mean') {
          return total / rows.length
        }

        return total
      case 'median':
      case 'extent':
      case 'uniqueCount':
        if (
          column.meta.dataType === 'date' &&
          column.meta.aggregationFunction === 'extent'
        ) {
          const allDateValues = rows
            .reduce((memo: Array<string>, row) => {
              if (row.getValue(id)) {
                memo.push(row.getValue(id))
              }

              return memo
            }, [])
            .flat()
            .filter(val => !!val)
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
          if (allDateValues.length === 1) {
            const value = allDateValues.shift()
            return [value, value]
          } else if (!allDateValues.length) {
            return null
          }

          return [allDateValues.shift(), allDateValues.pop()]
        } else {
          const allValues = rows
            .reduce((memo: Array<number>, row) => {
              if (row.getValue(id)) {
                memo.push(row.getValue(id) as number)
              }

              return memo
            }, [])
            .flat()
            .filter(val => !!val)
            .sort((a, b) => a - b)

          if (column.meta.aggregationFunction === 'extent') {
            if (allValues.length === 1) {
              const value = allValues.shift()
              return [value, value]
            } else if (!allValues.length) {
              return null
            }
            return [allValues.shift(), allValues.pop()]
          } else if (column.meta.aggregationFunction === 'uniqueCount') {
            return Array.from(new Set(allValues)).length
          }

          return findMedian(allValues)
        }

      default:
        return undefined
    }
  }
}

const operandToString = (
  operand: string | number | FormulaDefinition | undefined,
): string => {
  if (!!operand) {
    if (typeof operand === 'string' || typeof operand === 'number') {
      return `${operand}`
    }
    return `(${formulaToString(operand)})`
  }

  return ''
}

export function formulaToString(formula: FormulaDefinition): string {
  const firstOperand = operandToString(formula.firstValue)
  const secondOperand = operandToString(formula.secondValue)

  return `${firstOperand} ${formula.formulaName}${
    secondOperand ? ` ${secondOperand}` : ''
  }`
}

export function exportToCSV(tableInstance: Table<any>, fileName: string) {
  let csvContent = ''

  // Extract headers from table header groups
  tableInstance.getHeaderGroups().forEach(headerGroup => {
    const headers = headerGroup.headers
      .map(header => header.column.columnDef)
      .join(',')
    csvContent += headers + '\r\n'
  })

  // Iterate over rows and then cells
  tableInstance.getRowModel().rows.forEach(row => {
    const cellValues = row
      .getVisibleCells()
      .map(
        ({
          getValue,
          getIsAggregated,
          getIsPlaceholder,
          getIsGrouped,
          column,
        }) => {
          // Ensure that the cell value is properly escaped in case it contains commas
          const dataType = column.columnDef.meta?.dataType ?? ''

          if (getIsGrouped()) {
            return `"${getValue()}"`
          } else if (getIsPlaceholder()) {
            return ''
          } else if (getIsAggregated()) {
            if (!column.columnDef.meta?.aggregationFunction) {
              return ''
            }
            const value = getAggregatedValue(
              column.id,
              column.columnDef,
              row.getLeafRows(),
            )
            return value
              ? `"${
                  AGGREGATION_FUNCTION_LABELS_MAP[
                    column.columnDef.meta?.aggregationFunction as string
                  ]
                }: ${value}"`
              : ''
          } else {
            const value = formatValueByType(
              getValue() as string | number,
              dataType,
            )

            return value === '-' || !value ? '' : `"${value}"`
          }
        },
      )
      .join(',')

    csvContent += cellValues + '\r\n'
  })

  executeCsvDownload(csvContent, fileName)
}

const formulaMap: { [key: string]: FormulaName } = {
  '+': 'SUM',
  '-': 'SUBTRACT',
  '*': 'MULTIPLY',
  '/': 'DIVIDE',
}

export function replaceColumnNames(
  formula: FormulaDefinition,
  options: Array<any>,
) {
  if (formulaMap[formula.formulaName]) {
    formula.formulaName = formulaMap[formula.formulaName]
  } else {
    throw new Error(`Unknown formula ${formula.formulaName} applied`)
  }
  if (typeof formula.firstValue === 'string') {
    const foundColumn = options.find(
      option =>
        option.columnLabel === formula.firstValue ||
        option.columnName === formula.firstValue,
    )

    if (!foundColumn) {
      throw new Error(
        `Column ${formula.firstValue} does not exist. Please update prompt.`,
      )
    }

    formula.firstValue = foundColumn.columnName
  } else if (typeof formula.firstValue === 'object') {
    formula.firstValue = replaceColumnNames(
      formula.firstValue,
      options,
    ) as FormulaDefinition
  }

  if (typeof formula.secondValue === 'string') {
    const foundColumn = options.find(
      option =>
        option.columnLabel === formula.firstValue ||
        option.columnName === formula.firstValue,
    )

    if (!foundColumn) {
      throw new Error(
        `Column ${formula.secondValue} does not exist. Please update prompt.`,
      )
    }

    formula.secondValue = foundColumn.columnName
  } else if (typeof formula.secondValue === 'object') {
    formula.secondValue = replaceColumnNames(
      formula.secondValue,
      options,
    ) as FormulaDefinition
  }

  return formula
}
