import {
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { MdZoomIn } from 'react-icons/md'
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti'
import { formatValueByType } from '../../lib/pageUtils/reportUtils/utils'
import { usePipelineAnalytics } from './PipelineAnalyticsProvider'
import { BucketType, Field, Opportunity } from './Types'

type SortDirection = 'asc' | 'desc'

interface SortState {
  field: Field | null
  direction: SortDirection
}

type TableColumn = Field & {
  format?: (value: any) => string | number
  width?: string
  sortKey?: keyof Opportunity
}

interface CohortDrillDownProps {
  selectedCohort: string | null
  opportunitiesByCohort: Record<string, Opportunity[]>
  cohortField: string
  salesforceInstanceUrl?: string
  headerFields?: string[] // Field.name values
  renderCohortName: (cohort: string) => string
}

const DEFAULT_FIELD_NAMES = [
  'Name',
  'StageName',
  'Amount',
  'CreatedDate',
  'CloseDate',
  'OwnerId',
]

const NAME_FIELD = { name: 'Name', label: 'Name', type: 'string' } as const

export function useCohortDrillDown(
  cohortField: Field,
  bucketingScheme: BucketType,
) {
  const [selectedCohort, setSelectedCohort] = useState<string | null>(null)

  useEffect(() => {
    // If the cohort field is a date and bucketing scheme changes, reset the drilldown
    if (cohortField.type === 'date' || cohortField.type === 'datetime') {
      setSelectedCohort(null)
    }
  }, [bucketingScheme, cohortField])

  return {
    selectedCohort,
    setSelectedCohort,
  }
}

const RowsPerPage = ({
  value,
  onChange,
  maxRows,
}: {
  value: number
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  maxRows: number
}) => (
  <Flex align='center' fontSize='sm'>
    <Text mr={2}>Rows per page:</Text>
    <Select value={value} onChange={onChange} size='sm' width='auto'>
      <option value={25}>25</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
      <option value={maxRows}>All</option>
    </Select>
  </Flex>
)

const PaginationButtons = ({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}) => {
  const getPageNumbers = useMemo(() => {
    if (totalPages <= 3) {
      return Array.from({ length: totalPages }, (_, i) => i + 1)
    }

    const pages = []
    if (currentPage <= 2) {
      pages.push(1, 2, null, totalPages)
    } else if (currentPage >= totalPages - 1) {
      pages.push(1, null, totalPages - 1, totalPages)
    } else {
      pages.push(1, null, currentPage, null, totalPages)
    }
    return pages
  }, [currentPage, totalPages])

  return (
    <HStack spacing={1}>
      <Button
        size='sm'
        onClick={() => onPageChange(1)}
        isDisabled={currentPage === 1}
        variant='outline'
      >
        &laquo;
      </Button>
      <Button
        size='sm'
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        variant='outline'
      >
        &lsaquo;
      </Button>
      {getPageNumbers.map((pageNum, idx) =>
        pageNum === null ? (
          <Text key={`ellipsis-${idx}`}>...</Text>
        ) : (
          <Button
            key={pageNum}
            size='sm'
            onClick={() => onPageChange(pageNum)}
            variant={currentPage === pageNum ? 'solid' : 'outline'}
            colorScheme={currentPage === pageNum ? 'blue' : 'gray'}
          >
            {pageNum}
          </Button>
        ),
      )}
      <Button
        size='sm'
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
        variant='outline'
      >
        &rsaquo;
      </Button>
      <Button
        size='sm'
        onClick={() => onPageChange(totalPages)}
        isDisabled={currentPage === totalPages}
        variant='outline'
      >
        &raquo;
      </Button>
    </HStack>
  )
}

function SortIcon({
  field,
  sortState,
}: {
  field: string
  sortState: SortState
}) {
  const isSorted = sortState.field?.name === field
  const isAsc = sortState.direction === 'asc'

  return (
    <Icon
      as={
        isSorted
          ? isAsc
            ? TiArrowSortedUp
            : TiArrowSortedDown
          : TiArrowUnsorted
      }
      ml={1}
      color={isSorted ? 'gray.600' : 'gray.400'}
      boxSize={3}
    />
  )
}

export default function CohortDrillDown({
  selectedCohort,
  opportunitiesByCohort,
  cohortField,
  salesforceInstanceUrl = '',
  headerFields,
  renderCohortName,
}: CohortDrillDownProps) {
  const { data } = usePipelineAnalytics()
  const headingRef = useRef<HTMLHeadingElement>(null)
  const [showEmptyPopover, setShowEmptyPopover] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const [sortState, setSortState] = useState<SortState>({
    field: null,
    direction: 'asc',
  })

  // Handle empty cohort popover
  useEffect(() => {
    if (
      selectedCohort &&
      (!opportunitiesByCohort[selectedCohort] ||
        opportunitiesByCohort[selectedCohort].length === 0)
    ) {
      setShowEmptyPopover(true)
      const timer = setTimeout(() => setShowEmptyPopover(false), 3000)
      return () => clearTimeout(timer)
    }
  }, [selectedCohort, opportunitiesByCohort])

  // Scroll effect when cohort changes
  useEffect(() => {
    if (headingRef.current && selectedCohort) {
      const yOffset = -85 // 49px filter bar height + 36px buffer for comfortable spacing
      const element = headingRef.current
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [selectedCohort])

  // Reset current page when opportunities or sort changes
  useEffect(() => {
    setCurrentPage(1)
  }, [opportunitiesByCohort, sortState])

  const columns = useMemo(() => {
    if (!data?.fields) {
      return []
    }
    const fieldNamesToDisplay = headerFields ?? DEFAULT_FIELD_NAMES
    return (
      data.fields.some(field => field.name === 'Name')
        ? data.fields
        : [NAME_FIELD, ...data.fields]
    )
      .filter(field => fieldNamesToDisplay.includes(field.name))
      .map(field =>
        field.name === 'OwnerId' ? { ...field, label: 'Owner' } : field,
      )
  }, [data, headerFields])

  const sortedOpportunities = useMemo(() => {
    if (!selectedCohort || !opportunitiesByCohort[selectedCohort]) {
      return []
    }

    const opportunities = opportunitiesByCohort[selectedCohort]
    if (!sortState.field) {
      return opportunities
    }

    return opportunities.slice().sort((a, b) => {
      const field = sortState.field!
      const aValue = field.name === 'OwnerId' ? a.Owner?.Name : a[field.name]
      const bValue = field.name === 'OwnerId' ? b.Owner?.Name : b[field.name]

      // Handle null/undefined
      if (aValue == null && bValue == null) return 0
      if (aValue == null) return 1
      if (bValue == null) return -1

      const comparison = aValue < bValue ? -1 : aValue > bValue ? 1 : 0
      return sortState.direction === 'desc' ? -comparison : comparison
    })
  }, [selectedCohort, opportunitiesByCohort, sortState])

  const paginatedData = useMemo(
    () =>
      sortedOpportunities.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      ),
    [sortedOpportunities, currentPage, itemsPerPage],
  )

  // If no cohort is selected or it's empty, don't render anything
  if (
    !selectedCohort ||
    !opportunitiesByCohort[selectedCohort] ||
    opportunitiesByCohort[selectedCohort].length === 0
  ) {
    return null
  }

  const isOwnerField = cohortField.includes('Owner')
  const title = isOwnerField
    ? `Opportunities by ${renderCohortName(selectedCohort)}`
    : `Opportunities in ${renderCohortName(selectedCohort)}`

  const totalPages = Math.ceil(
    opportunitiesByCohort[selectedCohort].length / itemsPerPage,
  )

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setItemsPerPage(parseInt(event.target.value))
    setCurrentPage(1)
  }

  const formatValue = (opportunity: Opportunity, field: Field) => {
    if (field.name === 'OwnerId') {
      return opportunity.Owner?.Name || ''
    }
    const value = opportunity[field.name]
    if (typeof value === 'string' || typeof value === 'number') {
      return formatValueByType(value, field.type)
    }
    return '-'
  }

  const handleSort = (field: Field) => {
    setSortState(prev => ({
      field: prev.field?.name === field.name ? prev.field : field,
      direction:
        prev.field?.name === field.name && prev.direction === 'asc'
          ? 'desc'
          : 'asc',
    }))
  }

  return (
    <div style={{ marginTop: '24px', marginBottom: '24px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <h3 style={{ fontSize: '16px', fontWeight: '500' }}>{title}</h3>
      </div>

      <Table variant='simple' size='sm'>
        <Thead>
          <Tr>
            <Th width='32px' pr={2}></Th>
            {columns.map(field => (
              <Th
                key={field.name}
                cursor='pointer'
                userSelect='none'
                _hover={{ bg: 'gray.50' }}
                onClick={() => handleSort(field)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault()
                    handleSort(field)
                  } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                    e.preventDefault()
                    const headers = Array.from(
                      document.querySelectorAll('th[tabindex="0"]'),
                    )
                    const currentIndex = headers.indexOf(e.currentTarget)
                    const nextIndex =
                      e.key === 'ArrowLeft'
                        ? (currentIndex - 1 + headers.length) % headers.length
                        : (currentIndex + 1) % headers.length
                    ;(headers[nextIndex] as HTMLElement).focus()
                  }
                }}
                tabIndex={0}
              >
                <Flex align='center'>
                  <Text
                    fontWeight={
                      sortState.field?.name === field.name ? 'bold' : 'normal'
                    }
                  >
                    {field.label}
                  </Text>
                  <SortIcon field={field.name} sortState={sortState} />
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {paginatedData.map((opp, index) => (
            <Tr key={opp.Id}>
              <Td
                textAlign='center'
                color='gray.500'
                fontSize='xs'
                width='32px'
                pr={2}
              >
                {(currentPage - 1) * itemsPerPage + index + 1}
              </Td>
              {columns.map(field => (
                <Td key={field.name}>
                  {field.name === 'Name' ? (
                    salesforceInstanceUrl ? (
                      <Link
                        href={`${salesforceInstanceUrl}/lightning/r/Opportunity/${opp.Id}/view`}
                        isExternal
                        color='inherit'
                        _hover={{ color: '#3D4BCF' }}
                        display='flex'
                        alignItems='center'
                        gap='4px'
                      >
                        <Icon as={MdZoomIn} color='gray.500' boxSize={4} />
                        {opp.Name}
                      </Link>
                    ) : (
                      opp.Name
                    )
                  ) : (
                    formatValue(opp, field)
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      {opportunitiesByCohort[selectedCohort].length >= 25 && (
        <Flex justify='space-between' align='center' mt={2} mb={2}>
          <RowsPerPage
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            maxRows={opportunitiesByCohort[selectedCohort].length}
          />
          <PaginationButtons
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Flex>
      )}
    </div>
  )
}
